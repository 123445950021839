import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EditGroupProps, EditGroupState } from '../../../interfaces/menu';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../../secret';
import { editGroup, logoutUser, getGroup, menusList, storesListForMultiSelect } from '../../../redux';
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import Footer from '../../../components/footer/main';
import CheckChanges from '../../../components/confirmOnLeave';
import Select from 'react-select';

class EditGroup extends Component<EditGroupProps, EditGroupState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            groupname: "",
            menu: "",
            image: "",
            priority: "",
            poscode: "",
            erpid: "",
            is_lsm: '0',
            storesJson: [],
            mode: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.menusList();
        let id = this.props.match.params.id;
        this.props.getGroup(id);
        this.props.storesList();
        document.title = "SimplexCMS | Groups"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            groupname: nextProps.groupData.group_name,
            menu: nextProps.groupData.menu_id && nextProps.groupData.menu_id.menu_id,
            priority: nextProps.groupData.priority,
            erpid: nextProps.groupData.erp_id,
            mode: nextProps.groupData.mode && JSON.parse(nextProps.groupData.mode),
            storesJson: nextProps.groupData.stores_json ? JSON.parse(nextProps.groupData.stores_json) : this.state.storesJson,
            is_lsm: nextProps.groupData.is_lsm,
            poscode: nextProps.groupData.pos_code == 0 ? "" : nextProps.groupData.pos_code
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isGroupReady = () => {
        const { groupname, menu, mode, is_lsm, storesJson } = this.state;
        if (is_lsm == "0") {
            return (groupname !== "" && (mode && mode.length > 0) && menu !== "");
        } else if (is_lsm == "1") {
            return (groupname !== "" && (mode && mode.length > 0) && menu !== "" && storesJson.length > 0);
        }
    }
    handleSubmit = (event: any) => {
        let { groupname, menu, image, priority, poscode, erpid, mode, is_lsm, storesJson } = this.state;
        let id = this.props.match.params.id;
        const data: any = new FormData()
        if (image) {
            data.append('file', image[0])
        }
        data.append('group_name', groupname)
        data.append('menu_id', menu)
        data.append('priority', priority)
        data.append('mode', JSON.stringify(mode))
        if (poscode !== null && poscode !== "") {
            data.append('pos_code', poscode)
        } else {
            data.append('pos_code', null)
        }
        if (erpid !== null && erpid !== "") {
            data.append('erp_id', erpid)
        } else {
            data.append('erp_id', null)
        }
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        this.props.editGroup(id, data);
        event.preventDefault()
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, menus, groupData } = this.props;
        const { mode, is_lsm, storesJson } = this.state;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/edit-group" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Groups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/groups" className="text-primary">Groups</Link></li>
                                <li className="breadcrumb-item active">Edit Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <form method="post" className="form-validate">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Group Name <span className="text-danger">*</span></label>
                                                                <input id="groupname" type="text" name="groupname" onKeyDown={this.blockInvalidChar} defaultValue={groupData.group_name} required data-msg="Please enter Group Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">POS Code</label>
                                                                <input id="poscode" type="text" name="poscode" onKeyDown={this.blockInvalidChar} defaultValue={groupData.pos_code} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">ERP ID</label>
                                                                <input id="erpid" type="text" name="erpid" onKeyDown={this.blockInvalidChar} defaultValue={groupData.erp_id} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                                <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select type" onChange={this.handleInputChange}>
                                                                    <option disabled>Select Menu</option>
                                                                    {menus &&
                                                                        menus.map((menu, index) => (
                                                                            <option key={index} value={menu.menu_id} {...(groupData.menu_id && groupData.menu_id.menu_id) == menu.menu_id && { selected: true }}>{menu.menu_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Group Image <span className="text-danger"> {(groupData.group_image !== null) && `(1 Group Image)`}</span></label>
                                                                <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Priority</label>
                                                                <input id="priority" type="number" name="priority" min={0} onKeyDown={this.blockInvalidChar} defaultValue={groupData.priority} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="mode"
                                                                    options={this.orderModes}
                                                                    value={mode}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                            <div className="i-checks">
                                                                <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                                <label htmlFor="radioCustom3">Available for all stores</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                            <div className="i-checks">
                                                                <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                                <label htmlFor="radioCustom4">Available for specific stores</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            is_lsm == '1' &&
                                                            <div className="col" >
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        defaultValue={storesJson}
                                                                        name="stores"
                                                                        options={this.props.stores}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                        <button onClick={this.handleSubmit} disabled={!this.isGroupReady()} className="btn btn-primary">Update Group</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groupData: state.menu.groupData,
        stores: state.menu.storesoptions,
        message: state.menu.message,
        isUpdated: state.menu.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        menusList: function () {
            dispatch(menusList())
        },
        editGroup: function (id: any, data: any) {
            dispatch(editGroup(id, data));
        },
        getGroup: function (id: number) {
            dispatch(getGroup(id));
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);