import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import { logoutUser } from '../../../redux';
import { secretKey } from '../../../secret';
import { getBirthdayOffers, getLoyaltyTiers } from '../../../redux/actions/loyaltyAction';
import { BirthdayOffersStates, BirthdayOffersProps } from '../../../interfaces/birthdayOffers';

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblockitem(id, is_active, this.props.row.item_name);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.menu_item_id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                {/* <button title="View Details" data-toggle="modal" data-target={`#itemdetails${row.menu_item_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button> */}
                <Link title="Edit Item" className="btn btn-outline-primary ml-2" to={`/edit-birthday-offer/${row.id}`}><i className="fa fa-edit"></i></Link>
               
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.menu_item_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Menu Item</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this item?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.menu_item_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class DiscountGroupsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        console.log(row)
        return (
            <div>
                {row.birthday_offer_item_id &&
                    <button title="View Items" data-toggle="modal" data-target={`#itemdetails${row.id}group`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemdetails${row.id}group`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Offered Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <table style={{width: '100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th style={{width: '20%'}}>#</th>
                                                        <th>Item Name</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                            {row.birthday_offer_item_id.map((element:any,index:any)=> (
                                                <>
                                                <tr key={index}>
                                                    <td>{element.id}</td>
                                                    <td>{element.item_variant_id.size}</td>
                                                </tr>
                                                </>
                                            ))}
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function groupsFormatter(cell: any, row: any, props: any) {
    return (
        <DiscountGroupsFormatter row={row} data={props} />
    );
}

class BirthdayOffers extends Component<BirthdayOffersProps,BirthdayOffersStates> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.getBirthdayOffers();
        document.title = "SimplexCMS | Birthday Offers"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-birthday-offer" /> },
            noDataText: 'Birthday Offer Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">loyalty Tiers</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.birthdayOffers} search={true} exportCSV={true} options={options} insertRow csvFileName='menus.csv' hover>
                                                    <TableHeaderColumn dataField="id" isKey={true} thStyle={{ fontSize: 15, whiteSpace: 'normal' }} width='40' tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} >#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="birthday_offer_name" thStyle={{ fontSize: 15, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} >Offer Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="loyalty_name" thStyle={{ fontSize: 15, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} >Loyalty Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="loyalty_level" thStyle={{ fontSize: 15, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} >Loyalty Level</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="item_variant_name" thStyle={{ fontSize: 15, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataFormat={groupsFormatter}>Item Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='180' dataFormat={actionFormatter} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );

    }
}
const mapStateToProps = (state: any) => {
    return {
        birthdayOffers: state.loyalty.birthdayOffers,
        // groups: state.menu.groups
    }
    }
    const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getBirthdayOffers: function () {
            dispatch(getBirthdayOffers());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BirthdayOffers);