import Api from '../../components/Api';
import { toast } from "react-toastify";
import jwt from 'jsonwebtoken'
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import { BIRTHDAY_OFFERS, FETCH_LAST_LOYALTY_CODE, GET_BIRTHDAY_OFFER, GET_COFFEE_CARD_GROUP, GET_LOYALITY_GROUPS, GET_LOYALITY_ITEMS, GET_LOYALTY_COUNT, GET_LOYALTY_TIER, GET_SMOOTHIE_CARD_GROUP, LOYALTY_TIERS, SEARCH_GROUPS, SEARCH_MENU_ITEM } from './loyaltyType';
import { CUSTOMERS_LIST } from './customerType';
toast.configure();

//Crud apis for loyal tier
export const getLoyaltyTiers = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/simplex_loyalty/getAllLoyaltyTiers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse)
                    dispatch({
                        type: LOYALTY_TIERS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const saveLoyaltyTier = (data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/simplex_loyalty/add_loyalty',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/loyalty-tier"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Loyalty Tier exist with similar Loyalt name or loyalty level"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ")
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}


export const editLoyaltyTier = (id:any ,data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/simplex_loyalty/edit_loyalty/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/loyalty-tier"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getLoyaltyTier = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/simplex_loyalty/get_loyalty/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_LOYALTY_TIER,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

//CRUD operation for birthday Offers 
export const getBirthdayOffers = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/simplex_loyalty/getAllBirthdayOffers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse)
                    dispatch({
                        type: BIRTHDAY_OFFERS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const saveBirthdayOffer = (data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/simplex_loyalty/add_birthday_offer',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/birthday-offers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const editBirthdayOffer = (id:any ,data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/simplex_loyalty/edit_birthday_offer/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/birthday-offers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getBirthdayOffer = (id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/simplex_loyalty/get_birthday_offer/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_BIRTHDAY_OFFER,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getPrevLoyaltyCount = (name:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/simplex_loyalty/get_loyalty_count_details/${name}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse);
                    dispatch({
                        type: GET_LOYALTY_COUNT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

// getLoyalityGroups
export const getLoyalityGroups = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/simplex_loyalty/loyalityGroups/`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response.data.successResponse", response.data.successResponse)
                    dispatch({
                        type: GET_LOYALITY_GROUPS,
                        payload: response.data.successResponse,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addLoyalityCode = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let tokenDecode: any = jwt.decode(token);
        data.user_id = tokenDecode.user_id;
        Api.post('/simplex_loyalty/add_loyality_code',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success(`Code Saved. Code: ${data.code}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000000 });
                    window.location.href = "/loyalty-codes"
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateLoyalityGroup = (data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/simplex_loyalty/update_loyality_group',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/loyalty-groups"
                }
                if(!response.data.success){
                    toast.error(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const fetchLastCode = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let tokenDecode: any = jwt.decode(token);
        let user_id = tokenDecode.user_id;
        Api.get(`/simplex_loyalty/fetchLastCode/${user_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                
                
                if (response.data.success) {
                    dispatch({
                        type: FETCH_LAST_LOYALTY_CODE,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                console.log("}).catch(err => {",err);
                
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getCoffeeLoyaltyGroup = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/simplex_loyalty/get_coffee_loyalty_group', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_COFFEE_CARD_GROUP,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getSmoothieLoyaltyGroup = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/simplex_loyalty/get_smoothie_loyalty_group', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("GET_SMOOTHIE_CARD_GROUP",response.data.successResponse)
                    dispatch({
                        type: GET_SMOOTHIE_CARD_GROUP,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const searchCustomer = (phone: any, loyaltyId?: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data: any = {
            user_info: jwt.decode(token)
        }
        data.phone = phone;
        data.loyaltyId = loyaltyId;
        Api.post(`/simplex_loyalty/search_customer/`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CUSTOMERS_LIST,
                        payload: response.data.successResponse,
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: CUSTOMERS_LIST,
                        payload: [],
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const emptySearchCustomerList = () => {
    return function (dispatch: any) {
        dispatch({
            type: CUSTOMERS_LIST,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0
        })
    }
}

//post saveLoyaltyOrder
export const saveLoyaltyOrder = (data: any, history: any) => {
    return async (dispatch: any) => {
        let token: any = sessionStorage.getItem('token');
        if (token) {
            var decoded: any = jwt_decode(token);
            data.user_id = decoded.user_id;
        }
        console.log("BODY ______________ data",data);
        
        Api.post("simplex_loyalty/save_loyalty_order", data)
            .then((response) => {
                if (response.data.success) {
                    toast.success('Saved Successfully', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    window.location.reload();
                    // dispatch({
                    //   type: LOYALTY_ORDER_INFO,
                    //   payload: response.data.successResponse,
                    // });
                    // console.log("success combo");
                }
            })
            .catch((err) => {
                console.log("err", err);

                if (err && err.response) {
                    let error;
                    if (err.response.data && err.response.data.message && typeof err.response.data.message === "string") {
                        toast.warning(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                        error = err.response.data.message;
                    } else if (err.response.data && err.response.data.message && typeof err.response.data.message === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    // dispatch({
                    //   type: LOYALTY_ORDER_INFO,
                    //   payload: [],
                    // });
                } else {
                    // console.log("ok Combo");
                }
            });
    };
};