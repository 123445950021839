import React, { Component } from 'react';
import { EditDiscountProps, EditDiscountState } from '../../interfaces/discounts'
import { connect } from 'react-redux'
import { brandsList, getallActiveMenus, logoutUser, editDiscount, getDiscount, getAllActiveGroups, itemsListOfActiveMenu } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import moment from 'moment';
import Select from 'react-select';
class EditDiscount extends Component<EditDiscountProps, EditDiscountState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            discountvalue: "",
            expiryDate: "",
            percent: "",
            mode: [],
            channel: "",
            type: "",
            type_id: "",
            multiJson: [],
            discountType: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDiscountLevel = this.handleDiscountLevel.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getDiscount(id);
        this.props.itemsListOfActiveMenu();
        this.props.menusList();
        this.props.groupsList();
        document.title = "SimplexCMS | Discounts"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        this.setState({
            discountvalue: nextProps.discountData.discount_value,
            expiryDate: moment(nextProps.discountData.expire_date).format('YYYY-MM-DD'),
            percent: nextProps.discountData.percent,
            mode: nextProps.discountData.mode && JSON.parse(nextProps.discountData.mode),
            type: nextProps.discountData.type,
            type_id: nextProps.discountData.type_id,
            multiJson: nextProps.discountData.items_json && JSON.parse(nextProps.discountData.items_json),
            channel: nextProps.discountData.channel,
            discountType: (nextProps && nextProps.discountData.discount_value === 0 ? 'percentage' : 'value'),
        })

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    // handleCouponType(event: { target: { name: any; value: any; }; }) {
    //     this.setState({
    //         type_id: "",
    //         [event.target.name]: event.target.value,
    //     });
    // }
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            discountvalue: "",
            [event.target.name]: event.target.value,
        });
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    isDiscountReady = () => {
        const { expiryDate, mode, discountType, discountvalue, type, type_id, multiJson, percent } = this.state;
        if (type == "menu") {
            return (expiryDate !== "" && (mode && mode.length > 0) && type_id !== "" && discountType !== "" && (percent !== "" || discountvalue !== ""));
        } else if (type == "group" || type == "item") {
            return (expiryDate !== "" && (mode && mode.length > 0) && (multiJson && multiJson.length > 0) && discountType !== "" && (percent !== "" || discountvalue !== ""));
        }
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleDiscountLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            multiJson:[],
            [event.target.name]: event.target.value,
        });
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleSubmit = (event: any) => {
        let { discountvalue, expiryDate, mode, type, type_id, multiJson, discountType, percent } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            discount_value: discountvalue,
            discount_type: discountType,
            expire_date: expiryDate,
            mode: JSON.stringify(mode),
            type: type,
            percent: percent
        }
        if (type == "menu") {
            data.type_id = type_id;
            data.items_json = null;
        } else if (type == "group" || type == "item") {
            data.type_id = 0;
            data.items_json = JSON.stringify(multiJson);
        }
        this.props.editDiscount(id, data)
        event.preventDefault();
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, discountData, menus, items } = this.props;
        const { expiryDate, multiJson, mode } = this.state

        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/edit-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/2" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Edit Discount</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Level <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountLevel}>
                                                                <option value='menu' {...discountData.type === 'menu' && { selected: true }}>Menu</option>
                                                                <option value='group' {...discountData.type === 'group' && { selected: true }}>Group</option>
                                                                <option value='item' {...discountData.type === 'item' && { selected: true }}>Menu Item</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.type === "menu" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                    <option>Select Menu</option>
                                                                    {menus &&
                                                                        menus.map((menu: any, index: any) => (
                                                                            <option key={index} value={menu.menu_id} {...discountData.type_id === menu.menu_id && { selected: true }}>{menu.menu_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.type === "group" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={multiJson}
                                                                    name="groups"
                                                                    options={this.props.groups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.type === "item" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    defaultValue={this.state.multiJson}
                                                                    options={this.props.items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Type <span className="text-danger">*</span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option {...this.state.discountType === 'value' && { selected: true }} value='value'>Value</option>
                                                                <option {...this.state.discountType === 'percentage' && { selected: true }} value='percentage' >Percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Discount Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" defaultValue={discountData.percent} min="1" onKeyDown={this.blockInvalidChar} type="number" name="percent" required data-msg="Please enter Discount Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Discount value <span className="text-danger">*</span></label>
                                                                <input id="discountvalue" defaultValue={discountData.discount_value} min="1" onKeyDown={this.blockInvalidChar} type="number" name="discountvalue" required data-msg="Please enter Discount Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" value={expiryDate} required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                value={mode}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                        <div className="col py-4">
                                                            <div className="i-checks">
                                                                <input id="checkboxCustom1" type="checkbox" name="status" {...(discountData.is_archive === 0 && { defaultChecked: true }) || (discountData.is_archive === 1 && { defaultChecked: false })} onChange={this.handleChangeChk} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom1">Active</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                <div className="form-group float-right">
                                                    <button onClick={this.handleSubmit} disabled={!this.isDiscountReady()} className="btn btn-primary">Update Discount</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        discountData: state.discount.discountData,
        brands: state.store.brands,
        menus: state.menu.allActiveMenus,
        groups: state.menu.allActiveGroups,
        items: state.menu.allItemsOfActiveMenu,
        message: state.discount.message,
        isUpdated: state.discount.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editDiscount: function (id: any, data: any) {
            dispatch(editDiscount(id, data));
        },
        getDiscount: function (id: number) {
            dispatch(getDiscount(id));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function () {
            dispatch(getallActiveMenus())
        },
        groupsList: function () {
            dispatch(getAllActiveGroups())
        },
        itemsListOfActiveMenu: function () {
            dispatch(itemsListOfActiveMenu())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditDiscount);