import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Chart, { ChartUpdateProps } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Topbar from '../components/topbar'
import Sidebar from '../components/sidebar'
import Footer from '../components/footer/main'
import { secretKey } from '../secret'
import { connect } from 'react-redux'
import { currency } from '../client-config'
import { logoutUser, homeCounter, recentPayments, recentOrders, monthlyOrdersForLine, monthlySalesForBar, recentItems, leaderBoardForGraph, TopItemsList } from '../redux'
import { HomeProps } from '../interfaces/home';
import moment from 'moment';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DougnutChartChannel, LineChartData, PieChartSOS } from '../redux/actions/homeAction';
toast.configure();
Chart.plugins.register(ChartDataLabels);

class BarChart extends Component<{ data: any[] }, {}> {
    constructor(readonly props: any) {
        super(props)
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let sales = [];
        let stores = [];
        if (nextProps && nextProps.data) {
            for (let i = 0; i < nextProps.data.length; i++) {
                stores.push(nextProps.data[i].store_name);
                sales.push(nextProps.data[i].totalSales);
            }
        }
        var BARCHART: any = document.getElementById('barChart');

        let chart = new Chart(BARCHART, {
            type: 'bar',
            options:
            {
                scales:
                {
                    xAxes: [{
                        display: true
                    }],
                    yAxes: [{
                        display: true
                    }],
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            data: {
                labels: stores,
                datasets: [
                    {
                        label: "Stores",
                        backgroundColor: [
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)'
                        ],
                        borderColor: [
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)'
                        ],
                        borderWidth: 1,
                        data: sales
                    }
                ]
            }
        });
        chart.update({
            duration: 800,
            lazy: false,
            easing: 'easeOutBounce'
        });
    }
    render() {
        return <canvas id="barChart" />;
    }
}

class LineChart extends Component<{ data: any[] }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
        UNSAFE_componentWillReceiveProps(nextProps: any) {
        //console.log("nextProps!!!!", nextProps);
        let completedOrders = [];
        let totalSales = [];
        let weekDays: any = [];
        for (let i = 0; i < nextProps.data.length; i++) {
            let date = nextProps.data[i].date_created.split("T")
            weekDays.push(moment(date[0]).local().format("DD MMM"))
        }
        for (let i = 0; i < nextProps.data.length; i++) {
            completedOrders[i] = nextProps.data[i].completeOrders;
            totalSales[i] = nextProps.data[i].totalSales;
        }
        
        let sortWeekDays = weekDays.reverse()

        let LINECHART: any = document.getElementById('linechart');
        let lineChart = new Chart(LINECHART, {
            type: 'line',
            options: {
                responsive: true,
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            display: true
                        }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true
                        }
                    }]
                }
            },
            data: {
                labels: sortWeekDays,
                
                datasets: [
                    {
                        label: 'Order Completed',
                        data: completedOrders.reverse(),
                        fill: false,
                        backgroundColor: '#007bff',
                        borderColor: '#007bff',
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Total Sales',
                        data: totalSales.reverse(),
                        fill: false,
                        backgroundColor: '#fd7e14',
                        borderColor: '#fd7e14',
                        datalabels: {
                            display: false
                        }
                    }    
                ]
            }
        });
        
    }
    
    
    render() {
        return <canvas ref="chart" id="linechart" />;
    }
    
}

class PieChartSos extends Component<{ data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let dataa: any = [];
        let avgPendnig = nextProps.data.avgPendingTime ? nextProps.data.avgPendingTime : "";
        let avgKitchen = nextProps.data.avgKitchenTime ? nextProps.data.avgKitchenTime : "";
        let avgDispatch = nextProps.data.avgDispatchTime ? nextProps.data.avgDispatchTime : "";
        dataa.push(avgPendnig, avgKitchen, avgDispatch)
        
        let PIECHART: any = document.getElementById('piechart');
        new Chart(PIECHART, {
            type: 'pie',
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'SPEED OF SERVICES'
                }
            },
            data: {
                labels: ["Pending", "In Kitchen","Dispatch"],
                datasets: [
                    {
                        // label: 'SOS Pie Chart',
                        data: dataa,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                          ],
                          hoverOffset: 4,
                          datalabels: {
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'cursive'
                            }
                        }  
                    }]
            }
        });
    }
    render() {
        return (
        <div>
            <canvas id="piechart" />
        </div>
        );
    }
}

class DougnutChart extends Component<{ data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let dataa: any = [];
        // let mobile = nextProps.data && nextProps.data.mobile ? nextProps.data.mobile : "";
        let web = nextProps.data && nextProps.data.web ? nextProps.data.web : "";
        // let callCenter = nextProps.data && nextProps.data.callCenter ? nextProps.data.callCenter : "" ;
        dataa.push(web)
        
        let DAUGHNUT: any = document.getElementById('doughnutchart');
        new Chart(DAUGHNUT, {
            type: 'doughnut',
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'SALES BY CHANNEL'
                }
            },
            data: {
                // labels: ["Web", "Mobile","Call Center"],
                labels: ["Web"],
                datasets: [
                    {
                        label: 'Doughnut Chart',
                        data: dataa,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                          ],
                          hoverOffset: 4,
                          datalabels: {
                            formatter: (num) => {
                                if(num > 999 && num < 1000000){
                                    return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
                                }else if(num > 1000000){
                                    return (num/1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
                                }else if(num > 1000000000){
                                    return (num/1000000000).toFixed(0) + 'B'; // convert to B for number from > 1 billion 
                                }
                                else if(num < 900){
                                    return num; // if value < 1000, nothing to do
                                }
                            }, 
                            color: 'white',
                            //rotation: 60,
                            font: {
                                size: 14,
                                family: 'cursive'
                            }
                        } 
                    }]
            }
        });
    }
    render() {
        return (
        <div>
            <canvas id="doughnutchart" />
        </div>
        );
    }
}
class Home extends Component<HomeProps, { days?: any, startDate: any, endDate: any, validTimeFlag: any, [x: number]: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag: ""
        }
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this)
    }
    componentDidMount() {
        let obj = { days: this.state.days };
        this.props.homeCounter(obj);
        this.props.LineChartData();
        this.props.PieChartSOS();
        this.props.DougnutChartChannel();
        this.props.monthlyOrdersForLine(obj);
        this.props.monthlySalesForBar();
        this.props.recentOrders(obj);
        this.props.recentPayments(obj);
        this.props.leaderBoardForGraph(obj);
        this.props.recentItems();
        this.props.TopItemsList();
        document.title = "SimplexCMS | Home"
    }
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        let obj = { days: event.target.value };
        this.props.homeCounter(obj);
        this.props.monthlyOrdersForLine(obj);
        this.props.monthlySalesForBar();
        this.props.recentOrders(obj);
        this.props.recentPayments(obj);
        this.props.leaderBoardForGraph(obj);
        // console.log("event.target.value",event.target.value);
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isStatsDateReady = () => {
        let { startDate, endDate, validTimeFlag } = this.state;
        return (startDate !== "" && endDate !== "" && validTimeFlag == true);
    }
    handleSubmit() {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        this.props.homeCounter(data);
        this.props.monthlyOrdersForLine(data);
        this.props.recentOrders(data);
        this.props.recentPayments(data);
        this.props.leaderBoardForGraph(data);
    }
    render() {
        let roleId: any = ""
        const { counter, monthlyOrders, paymentData, orderData, itemsData, lineChart, dougnutChartChannel, pieChartSos, leaderBoard, topItems } = this.props;
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <h4 className="mt-2">Dashboard</h4>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isStatsDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Dashboard</h4>
                                    <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                                        <option value="0">Today</option>
                                        <option value="1">Yesterday</option>
                                        <option value="7">7 Days</option>
                                        <option value="15">15 Days</option>
                                        <option value="30">30 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="90">90 Days</option>
                                    </select>
                                </div> */}
                            </div>
                        </header>

                        {/* <!-- Dashboard Counts Section--> */}
                        <section className="dashboard-counts no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 2 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                                </Link>
                                                : <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                            }
                                            <div className="title"><span>Total Sales</span>
                                                <br /> <strong className="text-bold text-dark">{Math.round(counter.totalSales)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.totalSales / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.totalSales}</strong></div> */}
                                        </div>
                                    </div>


                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 3 || roleId === 4 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                                </Link>
                                                : <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            }
                                            <div className="title"><span>Ticket Avg </span>
                                                <br /><strong className="text-bold text-dark">{Math.round(counter.avgTicket)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.avgTicket / 100}%`, height: "4px" }} className="progress-bar bg-red"></div>   {/*ariaValuenow="70" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.avgTicket}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/payments">
                                                    <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                                </Link>
                                                : <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            }
                                            <div className="title"><span>Delivery Sales</span>
                                                <br /> <strong className="text-bold text-dark">{Math.round(counter.deliverySales)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.deliverySales / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.deliverySales}</strong></div> */}
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-sm-6" style={{ maxWidth: "23%" }}>
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/customers">
                                                    <div className="icon bg-violet"><i className="fa fa-users"></i></div>
                                                </Link>
                                                : <div className="icon bg-violet"><i className="fa fa-users"></i></div>
                                            }
                                            <div className="title"><span>Pickup <br /> Sales</span>
                                                <br /> <strong className="text-bold text-dark">{Math.round(counter.pickupSales)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.pickupSales / 100}%`, height: "4px" }} className="progress-bar bg-violet"></div>
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.pickupSales}</strong></div> */}
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 2 || roleId === 4 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                                </Link>
                                                : <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                            }
                                            <div className="title"><span>Avg Delivery <br /> Time</span>
                                                <br /><strong className="text-bold text-dark">{counter.avg_delivery_time ? counter.avg_delivery_time : 0}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.avg_delivery_time / 100}%`, height: "4px" }} className="progress-bar bg-red"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.avg_delivery_time}</strong></div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        {/* <!-- Dashboard Header Section    --> */}
                        <section className="dashboard-header pb-2">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Statistics --> */}
                                    <div className="statistics col-lg-3 col-12">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-orange"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.totalOrders}</strong><br /><small>Total Orders </small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-green"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.completeOrders}</strong><br /><small>Order Completed</small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.cancelOrders}</strong><br /><small>Orders Cancelled</small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.totalUsers}</strong><br /><small>Total Customers</small></div>
                                        </div>
                                    </div>
                                    {/* <!-- Line Chart --> */}
                                    <div className="chart col-lg-6 col-12">
                                        <div className="line-chart bg-white has-shadow" style={{ padding: "72px 0", height: "395px" }}>
                                            <LineChart ref="chart" data={lineChart} />
                                        </div>
                                    </div>

                                    <div className="statistics col-lg-3 col-12">
                                        {leaderBoard && leaderBoard.map((data: any, index: any) => (
                                            <div className="statistic d-flex align-items-center bg-white has-shadow" style={{ padding: '10px 15px' }}>
                                                {(() => {
                                                    if (index == 0) {
                                                        return (
                                                            <div className="icon bg-golden" ><i className="fa fa-shield"></i></div>
                                                        )
                                                    } else if (index == 1) {
                                                        return (
                                                            <div className="icon bg-silver" ><i className="fa fa-star"></i></div>
                                                        )
                                                    }
                                                    else if (index == 2) {
                                                        return (
                                                            <div className="icon bg-violet" ><i className="fa fa-star-half-o"></i></div>
                                                        )
                                                    }
                                                    else if (index == 3 || index == 4) {
                                                        return (
                                                            <div className="icon bg-red" ><i className="fa fa-star-o"></i></div>
                                                        )
                                                    }
                                                })()}

                                                <div className="text">{data.store_name}<br /><strong style={{ fontSize: '95%' }}>{currency}.{Math.round(data.totalSales)}</strong></div>
                                            </div>
                                            // <div>
                                            //     <div className="icon bg-red"></div>

                                            // </div>
                                        ))}


                                    </div>

                                </div>
                            </div>
                        </section>

                        <section className="dashboard-header py-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="chart col-lg-6 col-12">
                                        <div className="pie-chart has-shadow bg-white">
                                            <PieChartSos data={pieChartSos} />
                                            <div className="p-2">
                                                <small className="p-4">Average time in minutes</small>
                                                {/* <span className="p-1"><i className="fa fa-dot-circle-o" style={{color: "#DC3546" }}></i> {pieChartSos.avgPendingTime}</span>
                                                <span className="p-1"><i className="fa fa-dot-circle-o" style={{color: "#007bff" }}></i> {pieChartSos.avgKitchenTime}</span>
                                                <span className="p-1"><i className="fa fa-dot-circle-o" style={{color: "#ffc107" }}></i> {pieChartSos.avgDispatchTime}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart col-lg-6 col-12">
                                        <div className="doughnut-chart has-shadow bg-white">
                                            <DougnutChart data={dougnutChartChannel} />
                                            <div className="p-2">
                                                <small className="p-4">Total sales in AED</small>
                                                {/* <span className="p-1"><i className="fa fa-dot-circle-o" style={{color: "#DC3546" }}></i> {dougnutChartChannel && dougnutChartChannel.web}</span>
                                                <span className="p-1"><i className="fa fa-dot-circle-o" style={{color: "#007bff" }}></i> {dougnutChartChannel && dougnutChartChannel.mobile}</span>
                                                <span className="p-1"><i className="fa fa-dot-circle-o" style={{color: "#ffc107" }}></i> {dougnutChartChannel && dougnutChartChannel.callCenter}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- Updates Section --> */}
                        <section className="updates no-padding-top no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Recent Updates--> */}
                                    <div className="col-lg-12">
                                        <div className="recent-updates card">
                                            <div className="card-header">
                                                <strong>Recent Transactions</strong>
                                            </div>
                                            <div className="card-body">
                                                {paymentData.length > 0 ?
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-hover m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>OrderId</th>
                                                                    <th>Transaction Id</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Store</th>
                                                                    <th>Branch Code</th>
                                                                    <th>Customer</th>
                                                                    <th>Phone</th>
                                                                    <th>Amount({currency})</th>
                                                                    <th>Payment Status</th>
                                                                    <th>DateTime</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paymentData.map((data: any) => (
                                                                    <tr key={data.payment_id.toString()}>
                                                                        <td>{data.payment_id}</td>
                                                                        <td>{data.order_id}</td>
                                                                        <td>{data.transaction_id}</td>
                                                                        <td>{data.payment_method}</td>
                                                                        <td>{data.store_name}</td>
                                                                        <td>{data.branch_code}</td>
                                                                        <td>{data.first_name}</td>
                                                                        <td>{data.phone_number}</td>
                                                                        <td>{data.payment_amount}</td>
                                                                        <td className="text-capitalize"><span {...(data.payment_status === "succeeded" && { className: "badge badge-success p-2" }) || (data.payment_status === 'pending' && { className: "badge badge-info p-2" } || (data.payment_status === 'cancelled' && { className: "badge badge-danger p-2" }))}>{data.payment_status}</span></td>
                                                                        <td>{moment(data.date_modified).local().format('YYYY-MM-DD HH:mm').split(' ')[1]},{moment(data.date_modified).local().format('YYYY-MM-DD HH:mm').split(' ')[0]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <p className="text-center my-3">No Records Found</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- Feeds Section--> */}
                        <section className="feeds no-padding-top no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Latest Orders--> */}
                                    <div className="col-lg-7">
                                        <div className="articles card">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <strong>Latest Orders</strong>
                                                {(roleId === 1 || roleId === 3 || roleId === 4 || roleId === 7) &&
                                                    <ul className="mb-0">
                                                        <Link to="/orders"><button className="btn btn-primary p-1">View All Orders</button></Link>
                                                    </ul>
                                                }
                                            </div>
                                            <div className="card-body">
                                                {orderData.length > 0 ? <div className="table-responsive">
                                                    <table className="table table-striped table-hover m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Order</th>
                                                                <th>Customer</th>
                                                                <th>Phone</th>
                                                                <th>Payment Method</th>
                                                                <th>Price ({currency})</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderData.map((data: any) => (
                                                                <tr key={data.order_id}>
                                                                    <td>{data.order_id}</td>
                                                                    <td>{data.login_name}</td>
                                                                    <td>{data.phone_number}</td>
                                                                    <td>{data.payment_method}</td>
                                                                    <td>{(data.aggregator_orderId && data.aggregator_orderId > 0) ?
                                                                        data.order_grossprice : Math.round(data.order_grossprice)
                                                                    }</td>
                                                                    <td className="text-capitalize"><span {...(data.order_status_code === 1 && { className: "badge badge-primary p-1" }) || (data.order_status_code === 2 && { className: "badge badge-info p-1" }) || (data.order_status_code === 3 && { className: "badge badge-secondary p-1" }) || ((data.order_status_code === 4 || data.order_status_code === 5) && { className: "badge badge-success p-1" }) || (data.order_status_code === 6 && { className: "badge badge-danger p-1" }) || ((data.order_status_code === 7 || data.order_status_code === 8) && { className: "badge badge-secondary p-1" })}>{data.order_status_description}</span></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> : <p className="text-center my-3">No Records Found</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Check List --> */}
                                    <div className="col-lg-5">
                                        <div className="daily-feeds card">
                                            <div className="card-header">
                                                <strong>Top Selling Items</strong>
                                            </div>
                                            <div className="card-body no-padding">
                                                {topItems && topItems.length > 0 ?
                                                    <div>
                                                        {
                                                            topItems.map((data: any, index: any) => (
                                                                // Item 
                                                                (index <= 6) &&
                                                                <div key={data.menu_item_id} className="item p-3">
                                                                    <div className="feed d-flex justify-content-between text-capitalize">
                                                                        <div className="feed-body">
                                                                            <div className="content">
                                                                                <h6>{data.item_name}</h6>
                                                                                {data.item_description && <span>{data.item_description.length > 40 ? data.item_description.slice(0, 40).concat('...') : data.item_description} </span>}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <span className={data.is_publish === 0 ? "badge badge-warning p-2 text-dark" : "badge badge-success p-2 text-white"}>{data.is_publish === 0 ? "Un Published" : "Published"}</span></div>
                                                                    </div>
                                                                </div>


                                                            ))
                                                        }
                                                    </div>
                                                    : <p className="text-center p-4">No Records Found</p>}
                                            </div>
                                            {(roleId === 1 || roleId === 2 || roleId === 7) &&
                                                <div className="card-footer d-flex justify-content-center">
                                                    <Link to="/menu-items" className="text-primary">View All Menu Items</Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        monthlyOrders: state.home.monthlyOrders,
        monthlySales: state.home.monthlySales,
        lineChart: state.home.lineChart,
        dougnutChartChannel: state.home.dougnutChartChannel,
        pieChartSos: state.home.pieChartSos,
        counter: state.home.counter,
        paymentData: state.home.paymentData,
        orderData: state.home.orderData,
        itemsData: state.home.itemsData,
        leaderBoard: state.home.leaderBoard,
        topItems: state.home.topItemsList,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        DougnutChartChannel: function () {
            dispatch(DougnutChartChannel())
        }, 
        LineChartData: function () {
            dispatch(LineChartData())
        },
        PieChartSOS: function () {
            dispatch(PieChartSOS())
        },
        homeCounter: function (days: any) {
            dispatch(homeCounter(days))
        },
        monthlyOrdersForLine: function (days: any) {
            dispatch(monthlyOrdersForLine(days))
        },
        monthlySalesForBar: function () {
            dispatch(monthlySalesForBar())
        },
        recentPayments: function (days: any) {
            dispatch(recentPayments(days))
        },
        recentOrders: function (days: any) {
            dispatch(recentOrders(days))
        },
        leaderBoardForGraph: function (days: any) {
            dispatch(leaderBoardForGraph(days))
        },
        recentItems: function () {
            dispatch(recentItems())
        },
        TopItemsList: function () {
            dispatch(TopItemsList());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);