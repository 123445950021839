export const LOYALTY_TIERS = "LOYALTY_TIERS"
export const GET_LOYALTY_TIER = "GET_LOYALTY_TIER"
export const BIRTHDAY_OFFERS = "BIRTHDAY_OFFERS"
export const GET_BIRTHDAY_OFFER = "GET_BIRTHDAY_OFFER"
export const GET_LOYALTY_COUNT = "GET_LOYALTY_COUNT"
export const GET_LOYALITY_ITEMS = "GET_LOYALITY_ITEMS";
export const GET_LOYALITY_GROUPS = "GET_LOYALITY_GROUPS";
export const SEARCH_MENU_ITEM = "SEARCH_MENU_ITEM";
export const SEARCH_GROUPS = "SEARCH_GROUPS";
export const FETCH_LAST_LOYALTY_CODE = "FETCH_LAST_LOYALTY_CODE";
export const GET_SMOOTHIE_CARD_GROUP = "GET_SMOOTHIE_CARD_GROUP";
export const GET_COFFEE_CARD_GROUP = "GET_COFFEE_CARD_GROUP";
