import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { logoutUser, addLoyalityCode, fetchLastCode, getCoffeeLoyaltyGroup, getSmoothieLoyaltyGroup } from '../../../redux';
import { emptySearchCustomerList, saveLoyaltyOrder, searchCustomer } from '../../../redux/actions/loyaltyAction';
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"
import './style.css'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import moment from 'moment';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
var voucher_codes = require('voucher-code-generator');

interface MenuState {
    [x: number]: any,
    activeTab: any, status: any,
    statusReason: any,
    is_active: any,
    data: any,
    store: any,
    menuStoresOption: any,
    code: any,
    expiryTime: any,
    phone?: any,
    customerLookupCheck?: boolean,
    coffeeQuantity: any,
    smoothieQuantity: any,
    loyaltyCode: any,
    loyaltyCodeDisabled: any,
    setRadioSearch: any,
    loyaltyId: any,
}
interface MenuProps {
    history: any,
    addLoyalityCode: (value: any) => {},
    groupsData: any,
    itemsData: any,
    combosData: any,
    modifiersData: any,
    searchItemData: any,
    searchGroupData: any,
    searchModData: any,
    searchComboData: any,
    itemStatusReasons: any[],
    stores: any[],
    menuData: any,
    emptySearchCustomerList: () => {},
    loyaltyOrder: (data: any, loyaltyId: any) => {},
    searchCustomer: (data: any, loyaltyId: any) => {},
    searchMenuItem: (value: any, data: any) => {}
    searchGroup: (value: any, data: any) => {}
    searchCombo: (value: any, data: any) => {}
    searchModifiers: (value: any, data: any) => {}
    storesList: () => {},
    getAllMenuList: (store_id: any) => {},
    statusChange: () => {},
    updateItemStatus: (data: any, history: any) => {},
    menusList: () => {},
    fetchLastCode: () => {},
    getCoffeeLoyaltyGroup: () => {};
    getSmoothieLoyaltyGroup: () => {};
}

function dateFormatter(cell: any, row: any) {
    var DateModified: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
    DateModified = DateModified[1] + " , " + DateModified[0];
    return (
        <div>
            <span className={row.expiryValid == 1 ? 'p-1 badge-success' : 'p-1 badge-danger'} {...((cell !== "" && cell !== null) && { title: DateModified })}> {(cell !== "" && cell !== null) && DateModified} </span>
        </div>
    )
}
function redeemedFormatter(cell: any) {
    return (
        <div>
            <span className={cell == 0 ? 'p-1 badge-success' : 'p-1 badge-danger'} title={(cell == 0 ? 'Not Redeemed' : 'Redeemed')}>{(cell == 0 ? 'Not Redeemed' : 'Redeemed')}</span>
        </div>
    )
}
function phoneNumberFormatter(cell: any) {
    let phoneNumber = cell.length > 0 ? cell.slice(0, -4) + '****' : '';
    return (
        <div>{phoneNumber}</div>
    )
}
class LoyaltyCodes extends Component<MenuProps, MenuState> {
    openModal: any;
    closeModal: any;
    storesDropDown: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            status: false,
            is_active: "",
            data: {},
            statusReason: "",
            store: "",
            menuStoresOption: [],
            code: "",
            expiryTime: "5",
            phone: "",
            customerLookupCheck: false,
            coffeeQuantity: "",
            smoothieQuantity: "",
            loyaltyCode: "",
            loyaltyCodeDisabled: false,
            setRadioSearch: "phoneSearch",
            loyaltyId: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | Loyalty";
        this.props.fetchLastCode();
        this.props.getCoffeeLoyaltyGroup();
        this.props.getSmoothieLoyaltyGroup();
    }
    handleSaveBtnClick = (event: any) => {
        console.log("handleSaveBtnClick = (event: any) => {");
        let { code, expiryTime } = this.state;
        let data = {
            "code": code,
            "expiry_time": expiryTime
        }
        this.props.addLoyalityCode(data);

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "menu_item") {
            this.props.searchMenuItem(event.target.value, this.props.itemsData)
        } else if (event.target.name == "group") {
            this.props.searchGroup(event.target.value, this.props.groupsData)
        } else if (event.target.name == "combo") {
            this.props.searchCombo(event.target.value, this.props.combosData)
        } else if (event.target.name == "modifier") {
            this.props.searchModifiers(event.target.value, this.props.modifiersData)
        }
    }
    generateLoyaltyCode = () => {
        let code: any[] = voucher_codes.generate({
            length: 5,
            count: 1,
            charset: voucher_codes.charset("numbers")
        });
        this.setState({ code: code[0] });
    }
    isFormReady = () => {
        // const { data } = this.props;
        // return (code !== "" && expiryTime !== "" && data && data.length > 0);
        const { code, expiryTime } = this.state;
        return (code !== "" && expiryTime !== "");

    }

    customerSubmitDisabled() {
        const { phone, loyaltyId } = this.state;
        return (phone !== "" || loyaltyId !== "");
    }

    handleCustomerSubmit = (event: any) => {
        event.preventDefault();

        let { phone, loyaltyId } = this.state;
        this.setState({ customerLookupCheck: true })
        this.props.searchCustomer(phone, loyaltyId)
    }

    handleOnPhoneChange = (value: any, data: any) => {
        this.setState({ phone: value });
    };

    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.props.emptySearchCustomerList();
        let { data } = this.props;
        data = [];
        if (event.target.value == "phoneSearch") {
            this.setState({ loyaltyId: '' })
        } else {
            this.setState({ phone: '' })
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    isLoyaltyOrderValid = () => {
        let { smoothieQuantity, coffeeQuantity } = this.state;
        return (smoothieQuantity > 0 || coffeeQuantity > 0);
    };

    handleLoyaltyOrderSubmit = (event: any) => {
        let { smoothieQuantity, coffeeQuantity } = this.state;
        let { data } = this.props;
        if (data && data.length > 0) {
            let order: any = {
                "customer_id": data[0].customer_id,
                "dataArr": []
            }
            let coffeeData: any = {
                "loyalty_card_group_id": 1,
                "quantity": coffeeQuantity
            }
            let smoothieData: any = {
                "loyalty_card_group_id": 2,
                "quantity": smoothieQuantity
            }

            if (coffeeQuantity > 0) {
                order.dataArr.push(coffeeData)
            }
            if (smoothieQuantity > 0) {
                order.dataArr.push(smoothieData)
            }

            if (order && order.dataArr.length > 0) {
                this.props.loyaltyOrder(order, this.props.history);
            }
            console.table(order)
        } else {
            toast.error("Customer not Selected", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
        }

    };

    render() {
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'No Data'
        };
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { loyaltyCodeDisabled, setRadioSearch } = this.state;
        let { lastLoyaltyCode, data, smoothieCardGroupData, coffeeCardGroupData } = this.props;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <div>
                                        <h4 className="mt-2">Loyalty Codes</h4>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="tables pt-4">
                            <div className="container-fluid">
                                {/* CUSTOMER LOOKUP */}
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="card-header border-bottom-grey">
                                                    <strong className="">Customer Lookup</strong>
                                                </div>
                                                <div className="card-body p-0 mb-5">
                                                    <div className="container-fluid">
                                                        <div className="row d-flex justify-content-center mt-3">
                                                            <div className='mr-3'>
                                                                <div className="i-checks">
                                                                    <input id="radioCustom1" type="radio" name="setRadioSearch" value="phoneSearch" checked={setRadioSearch === 'phoneSearch'} className="radio-template" onChange={this.handleChangeRad} />
                                                                    <label htmlFor="radioCustom1">Search by Phone</label>
                                                                </div>
                                                            </div>
                                                            <div className='ml-3'>
                                                                <div className="i-checks">
                                                                    <input id="radioCustom2" type="radio" name="setRadioSearch" value="loyaltyIdSearch" checked={setRadioSearch === 'loyaltyIdSearch'} className="radio-template" onChange={this.handleChangeRad} />
                                                                    <label htmlFor="radioCustom2">Search by Loyalty ID</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-between align-items-end mt-4">
                                                            <div className="col">
                                                                {setRadioSearch == "phoneSearch" &&
                                                                    <div className="form-group">
                                                                        <label className="form-control-lable">Phone</label>
                                                                        <p style={{ fontSize: '13px', color: 'grey' }}>Enter your phone number with (XX-XX-XX-XXX)</p>
                                                                        {/* <input id="phone" type="number" value={this.state.phone} onKeyDown={this.blockInvalidCharPhone} name="phone" required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} /> */}
                                                                        <PhoneInput
                                                                            containerClass="w-100"
                                                                            inputClass="w-100"
                                                                            placeholder="** ** ** ***"
                                                                            inputStyle={{ height: '40px' }}
                                                                            inputProps={{
                                                                                name: 'phone',
                                                                                required: true,
                                                                                autoFocus: true
                                                                            }}
                                                                            country={'ae'}
                                                                            disableDropdown={true}
                                                                            onlyCountries={['ae']}
                                                                            countryCodeEditable={false}
                                                                            value={this.state.phone}
                                                                            onChange={this.handleOnPhoneChange}
                                                                        />
                                                                    </div>
                                                                }

                                                                {setRadioSearch == "loyaltyIdSearch" &&
                                                                    <div className="form-group">
                                                                        <label className="form-control-lable">Loyalty ID</label>
                                                                        <input id="loyaltyId" type="number" value={this.state.loyaltyId} name="loyaltyId" required data-msg="Please enter Loyalty Id" className="input-material" onChange={this.handleInputChange} />

                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <button onClick={this.handleCustomerSubmit} disabled={!this.customerSubmitDisabled()} className="btn btn-primary">Search Customer</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* CUSTOMER INFO */}
                                {this.state.customerLookupCheck &&
                                    <div className="row">
                                        <div className="col">
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Customer Info</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <BootstrapTable data={data} search={false} pagination={false} options={options} hover>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='customer_id' csvHeader='Loyalty ID' width='50' dataSort={true} isKey>Loyalty ID</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='login_name' csvHeader='Name' width='50'>Name</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' dataFormat={phoneNumberFormatter} width='100' columnTitle>Phone</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* LOYALTY ORDER */}
                                {data && data.length > 0 && smoothieCardGroupData && coffeeCardGroupData && (smoothieCardGroupData.is_active == 1 || coffeeCardGroupData.is_active == 1) &&
                                    <div className="row">
                                        <div className="col">
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Loyalty Order</strong>
                                                    </div>
                                                    <div className="card-body p-0 mb-5">
                                                        <div className="container-fluid">
                                                            <div className="row my-3 d-flex justify-content-between align-items-center">
                                                                {coffeeCardGroupData && coffeeCardGroupData.is_active == 1 &&
                                                                    <div className='col-6'>
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Coffee Quantity <span className="text-danger">*</span></label>
                                                                            <input id="coffeeQuantity" type="text" name="coffeeQuantity" value={this.state.coffeeQuantity} data-msg="Please enter Coffee Quantity" className="input-material" onChange={this.handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {smoothieCardGroupData && smoothieCardGroupData.is_active == 1 &&
                                                                    <div className='col-6'>
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Smoothie Quantity <span className="text-danger">*</span></label>
                                                                            <input id="smoothieQuantity" type="text" name="smoothieQuantity" value={this.state.smoothieQuantity} data-msg="Please enter Smoothie Quantity" className="input-material" onChange={this.handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {loyaltyCodeDisabled &&
                                                                    <div className='col-4'>
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Loyalty Code <span className="text-danger">*</span></label>
                                                                            <input id="loyaltyCode" type="text" name="loyaltyCode" value={this.state.loyaltyCode} data-msg="Please enter Loyalty Code" className="input-material" onChange={this.handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            {loyaltyCodeDisabled &&
                                                                <div className="form-group d-flex justify-content-end mt-4">
                                                                    <button className='btn btn-primary' disabled={!this.isFormReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                                </div>
                                                            }
                                                            <div className="form-group d-flex justify-content-end mt-4">
                                                                <button className='btn btn-primary' disabled={!this.isLoyaltyOrderValid()} onClick={this.handleLoyaltyOrderSubmit}>Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <p></p>
                                {loyaltyCodeDisabled &&
                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="card">
                                                    <div className="card-content">
                                                        <div className="card-header border-bottom-grey">
                                                            <strong className="">New Loyalty Code</strong>
                                                        </div>
                                                        <div className="card-body p-0 mb-5">
                                                            <div className="container-fluid">
                                                                <div className="row my-3 d-flex justify-content-between align-items-center">
                                                                    <div className="col-12">
                                                                        <button className="btn btn-primary" title='Generate Loyalty Code' onClick={() => this.generateLoyaltyCode()}><i className="fa fa-refresh mr-2"></i>Generate Loyalty Code</button>
                                                                    </div>
                                                                </div>

                                                                <div className="row my-3 d-flex justify-content-between align-items-center">
                                                                    <div className='col-6'>
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Loyalty Code <span className="text-danger">*</span></label>
                                                                            <input id="code" type="text" name="code" value={this.state.code} data-msg="Please enter Loyalty Code" className="input-material" onChange={this.handleInputChange} readOnly />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Expiry Time <span className="text-danger">*</span></label>
                                                                            <input id="expiryTime" type="text" name="expiryTime" value={this.state.expiryTime} data-msg="Please enter Loyalty Code" className="input-material" onChange={this.handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group d-flex justify-content-end mt-4">
                                                                    <button className='btn btn-primary' disabled={!this.isFormReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="card">
                                                    <div className="card-content">
                                                        <div className="card-header border-bottom-grey">
                                                            <strong className="">Last Loyalty Code</strong>
                                                        </div>
                                                        <div className="card-body">
                                                            <BootstrapTable data={lastLoyaltyCode} search={false} pagination={false} options={options} hover>
                                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='code' csvHeader='Code' width='50' dataSort={true} isKey>Code</TableHeaderColumn>
                                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='redeemed' csvHeader='Redeemed' dataFormat={redeemedFormatter} width='100' columnTitle>Redeemed</TableHeaderColumn>
                                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='expiry_date' csvHeader='Expiry' dataFormat={dateFormatter} width='100' columnTitle>Expiry Time</TableHeaderColumn>
                                                            </BootstrapTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    console.log("state", state);

    return {
        lastLoyaltyCode: state.loyalty.lastLoyaltyCode,
        data: state.customer.data,
        coffeeCardGroupData: state.loyalty.coffeeCardGroupData,
        smoothieCardGroupData: state.loyalty.smoothieCardGroup,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addLoyalityCode: (data: any) => {
            dispatch(addLoyalityCode(data));
        },
        fetchLastCode: () => {
            dispatch(fetchLastCode());
        },
        searchCustomer: (phone: any, loyaltyId: any) => {
            dispatch(searchCustomer(phone, loyaltyId))
        },
        emptySearchCustomerList: () => {
            dispatch(emptySearchCustomerList())
        },
        getCoffeeLoyaltyGroup: function () {
            dispatch(getCoffeeLoyaltyGroup());
        },
        getSmoothieLoyaltyGroup: function () {
            dispatch(getSmoothieLoyaltyGroup());
        },
        loyaltyOrder: function (data: any, history: any) {
            dispatch(saveLoyaltyOrder(data, history));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyCodes);