import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AddLoyaltyTierProps, AddLoyaltyTierState } from '../../../interfaces/loyalty-tier'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addCombo, channelList, logoutUser, groupedItemsList, menusList, groupsList, storesListForMultiSelect } from '../../../redux'
import Select from "react-select";
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import { comboChoiceItemsByGroupId, combosList } from '../../../redux/actions/menuAction';
import { getLoyaltyTiers, getPrevLoyaltyCount, saveLoyaltyTier } from '../../../redux/actions/loyaltyAction';
import './loyalty.css'
import { toast } from 'react-toastify';
class AddLoyaltyTier extends Component<AddLoyaltyTierProps, AddLoyaltyTierState>{
    orderModes: ({ value: string; label: string })[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'First', label: 'First' },
            { value: 'Second', label: 'Second' },
            { value: 'Third', label: 'Third' }
        ];
        this.state = {
            loyaltyName: "",
            LoyaltyLevel: "",
            MinOrder: "",
            MaxOrder: "",
            MinOrderVal: "",
            MaxOrderVal: 1,
            CashbackType: "value",
            earnCashbackType: "value",
            earn_cashback_value: "",
            CashbackVAlue: "",
            cashback_value: "",
            CapedVal: "",
            capped_value: "",
            expiry_date: "",
            Discount: 1


        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        //     this.props.combosList();
        //     this.props.groupsList();
        //     this.props.menusList();
        //     this.props.channelList();
        //     this.props.storesList();
        this.props.getLoyaltyTiers()
        document.title = "SimplexCMS | Loyalty Tiers"
    }
    componentDidUpdate(nextProps: any) {
        if (nextProps.loyaltyCountData !== this.props.loyaltyCountData) {
            this.setState({
                MinOrder: this.props.loyaltyCountData.max_order_count,
                MinOrderVal: this.props.loyaltyCountData.max_order_value
            })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
          }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSelectInputChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value != 'First') {
            this.props.getPrevLoyaltyCount(event.target.value)
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }



    handleSaveBtnClick = (event: any) => {
        let { Discount, capped_value, CashbackType, earnCashbackType, earn_cashback_value, MaxOrderVal, MinOrderVal, MaxOrder, MinOrder, loyaltyName, LoyaltyLevel } = this.state;
        let { loyaltyTiers } = this.props;
        let validateFlag = true;
        let obj: any = {
            loyalty_name: loyaltyName,
            loyalty_level: LoyaltyLevel,
            min_order_count: MinOrder,
            max_order_count: MaxOrder,
            min_order_value: MinOrderVal,
            max_order_value: MaxOrderVal,
            earn_cashback_type: earnCashbackType,
            earn_cashback_value: earn_cashback_value,
            cashback_type: CashbackType,
            cashback_value: this.state.cashback_value,
            capped_value: capped_value,
            points_valid_till: this.state.expiry_date,
            discount_included: Discount,
            is_active: 1
        }
        console.log("here", obj.max_order_count, obj.min_order_count)
        if(obj.min_order_count <= 0 || obj.max_order_count <= 0) {
            validateFlag = false;
            toast.error("Minimum and Maximum order count should be greater than zero")
        }
        else if(obj.max_order_value <= 0 || obj.min_order_value <= 0) {
            validateFlag = false;
            toast.error("Minimum and Maximum order value should be greater than zero")
        }
        else if(obj.earn_cashback_value <= 0 || obj.burn_cashback_value <= 0) {
            validateFlag = false;
            toast.error("Earn cashback and burn cashback should be greater than zero")
        }
        else if(obj.max_order_count < obj.min_order_count) {
            validateFlag = false;
            toast.error("Maximum order count should be greater than " + obj.min_order_count)
        }
        else if(obj.max_order_value <= obj.min_order_value) {
            validateFlag = false;
            toast.error("Maximum order value should be greater than " + obj.min_order_value)
        }
        else if(obj.capped_value <= 0 ) {
            validateFlag = false;
            toast.error("Capped value should be greater than zero")
        }
        else if(obj.points_valid_till <= 1 ) {
            validateFlag = false;
            toast.error("Expiry days should be greater than 1")
        }
        else if(obj.earn_cashback_value > 100 && obj.earn_cashback_type == "percentage" || obj.cashback_value > 100 && obj.cashback_type == "percentage") {
            validateFlag = false;
            toast.error("Cashback Earn or Cashback Burn should not be greater than 100")
        }
        if(loyaltyTiers.length > 0 && validateFlag) {
            loyaltyTiers.map((tier: any) => {
                if(obj.loyalty_level == tier.loyalty_level) {
                    validateFlag = false;
                    toast.error(obj.loyalty_level+" Tier level already exist")
                }
                else if (obj.loyalty_name == tier.loyalty_name) {
                    validateFlag = false;
                    toast.error("Loyalty name already exist")
                }
                else if (obj.min_order_count >= tier.min_order_count && obj.min_order_count <= tier.max_order_count || obj.max_order_count >= tier.min_order_count && obj.max_order_count <= tier.max_order_count) {
                    validateFlag = false;
                    toast.error("Minimum or maximum order count range is invalid")
                }
                else if (obj.min_order_value >= tier.min_order_value && obj.min_order_value <= tier.max_order_value || obj.max_order_value >= tier.min_order_value && obj.max_order_value <= tier.max_order_value) {
                    validateFlag = false;
                    toast.error("Minimum or maximum order value range is invalid")
                }
            })
        }
        if(validateFlag) {
        this.props.saveLoyaltyTier(obj);
        }
        // addLoyalty Tier data below
        // addLoyalty Tier data above
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        // const { isInserted, message, menus, channels, combooptions, groups } = this.props;
        console.log(this.state.CashbackType, "CashbackType")
        return (
            <div className="page">
                <CheckChanges path="/add-combo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Loyalty Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/loyalty-tier" className="text-primary">Loyalty tier</Link></li>
                                <li className="breadcrumb-item active">Add Loyalty</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100 w-100">Loyalty Name <span className="text-danger">*</span> 
                                                           
                                                            </label>
                                                            <input id="loyaltyName" onKeyDown={this.blockInvalidChar} type="text" name="loyaltyName" required data-msg="Please Enter Loyalty Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Loyalty Level <span className="text-danger">*</span></label>
                                                            <select id="MinOrder" name="LoyaltyLevel" onChange={this.handleSelectInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                <option value="select">Select Loyalty Level</option>

                                                                <option value='First'>First</option>
                                                                <option value='Second'>Second</option>
                                                                <option value='Third'>Third</option>
                                                            </select>
                                                            {/* <input id="LoyaltyLevel" type="text" name="LoyaltyLevel" className="input-material" onChange={this.handleInputChange} /> */}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Min Order Count  <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Minimum order count represents the minimum number of orders user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input id="MinOrder" onKeyDown={this.blockInvalidChar} type="number" value={this.state.MinOrder} name="MinOrder" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Max Order Count <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Maximum order count represents the maximum number of orders user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input id="MaxOrder" onKeyDown={this.blockInvalidChar} type="number" name="MaxOrder" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Min Order Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Minimum order value represents the minimum orders value user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="MinOrderVal" onKeyDown={this.blockInvalidChar} type='number' value={this.state.MinOrderVal} className="input-material text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Max Order Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Maximum orders value represents the maximum orders value user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="MaxOrderVal" onKeyDown={this.blockInvalidChar} type='number' className="input-material text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Earn Cashback Type <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Earn Cashback Type represents in which cashback type users earn points.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <select id="CashbackType" name="earnCashbackType" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu" >
                                                                <option value="value">value</option>
                                                                <option value="percentage">percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Earn Cashback {this.state.earnCashbackType == 'value'? "Value" : "Percentage"} <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Earn Cashback Value represents how much points user will be allocated on each order.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="earn_cashback_value" onKeyDown={this.blockInvalidChar} type='number' className="input-material text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Burn Cashback Type <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Burn Cashback Type represent in which cashback type users radeem loyalty points.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <select id="CashbackType" name="CashbackType" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu" >
                                                                <option value="value">value</option>
                                                                <option value="percentage">percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Burn Cashback {this.state.CashbackType == 'value' ? "Value" : "Percentage" }  <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Burn Cashback Value represent how much cashback value users will radeem loyalty points on each order.">
                                                            i
                                                            </span> 
                                                            </label>
                                                            
                                                            <input name="cashback_value" onKeyDown={this.blockInvalidChar} type='number' className="input-material text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Capped Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Capped Value is the Maximum limit of radeem points on a single order.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input
                                                                name="capped_value"
                                                                onKeyDown={this.blockInvalidChar}
                                                                type="number"
                                                                data-msg="Please enter group name"
                                                                className="input-material"
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Discount Included <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Can user radeem points on discount promotion or discounted items?">
                                                            i
                                                            </span>
                                                            </label>
                                                            <select id="MinOrder" name="Discount" onChange={this.handleSelectInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                <option value="select">Is Discount Included</option>
                                                                <option value={1}>Yes</option>
                                                                <option value={0}>No</option>
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Loyalty Rewards Expiry After Days <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Number of days till how many days users points remain valid in this loyalty tier.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input
                                                                name="expiry_date"
                                                                onKeyDown={this.blockInvalidChar}
                                                                type="number"
                                                                data-msg="Please enter group name"
                                                                className="input-material"
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        loyaltyCountData: state.loyalty.loyaltyCountData,
        loyaltyTiers: state.loyalty.loyaltyTiers,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        saveLoyaltyTier: function (data: any) {
            dispatch(saveLoyaltyTier(data));
        },
        getPrevLoyaltyCount: function (name: any) {
            dispatch(getPrevLoyaltyCount(name));
        },
        getLoyaltyTiers: function () {
            dispatch(getLoyaltyTiers());
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddLoyaltyTier);