import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, delRole, rolesList } from '../../redux'
import { RolesProps } from '../../interfaces/settings';

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.delete(id, this.props.row.role_name)
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title="Delete Role" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelRole${row.role_id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit Role" className="btn btn-outline-primary ml-2" to={`/edit-role/${row.role_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelRole${row.role_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Role</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this role?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.role_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} delete={props.delRole} />
    );
}
class Roles extends Component<RolesProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.rolesList();
        document.title = "SimplexCMS | Roles"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-role" /> },
            noDataText: 'Roles Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Roles Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable data={this.props.roles} search={true} pagination={this.props.roles.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='roles.csv' hover>
                                                    <TableHeaderColumn dataField='role_id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='role_name' csvHeader='Name' width='100' columnTitle>Role</TableHeaderColumn>
                                                    <TableHeaderColumn tdStyle={{ textTransform: 'capitalize' }} dataField='type' csvHeader='Type' width='100' columnTitle>Type</TableHeaderColumn>
                                                    {roleId == 7 && <TableHeaderColumn dataField='action' width='120' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>}
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>

                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        roles: state.setting.roles
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        rolesList: function () {
            dispatch(rolesList())
        },
        delRole: function (id: any, role_name: any) {
            dispatch(delRole(id, role_name))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Roles);