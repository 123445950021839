import React, { Component } from 'react';
import { EditLoyaltyTierProps, EditLoyaltyTierState } from '../../../interfaces/loyalty-tier'
import { connect } from 'react-redux'
import { editCombo, getCombo, menusList, groupedItemsList, channelList, getTaxValue, logoutUser, statesList, groupsList, storesListForMultiSelect } from '../../../redux'
import Footer from '../../../components/footer/main';
import jwt from 'jsonwebtoken'
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import { Link, Redirect } from 'react-router-dom';
import Select from "react-select";
import { secretKey } from '../../../secret';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import { comboChoiceItemsByGroupId } from '../../../redux/actions/menuAction';
import { flattenDiagnosticMessageText } from 'typescript';
import { editLoyaltyTier, getLoyaltyTier, getLoyaltyTiers } from '../../../redux/actions/loyaltyAction';
import './loyalty.css';
import { toast } from 'react-toastify';

class EditLoyalty extends Component<EditLoyaltyTierProps, EditLoyaltyTierState>{
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            loyaltyName: "Item name 1",
            LoyaltyLevel: "",
            MinOrder: 1,
            MaxOrder: 1,
            MinOrderVal: 1,
            MaxOrderVal: 1,
            earnCashbackType: "",
            earnCashbackValue: "",
            CashbackType: "",
            CashbackVAlue: "",
            CapedVal: "some",
            expiry_date: 0,
            Discount: 1


        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getLoyaltyTiers();
        this.props.getLoyaltyTier(id);
        document.title = "SimplexCMS | LoyaltyTier"
    }

    componentDidUpdate(nextProps: any) {
        if (this.props.loyaltyData !== nextProps.loyaltyData) {  //this condition is used
            console.log("this.prop",this.props.loyaltyData)
        this.setState({
            loyaltyName: this.props.loyaltyData.loyalty_name,
            LoyaltyLevel: this.props.loyaltyData.loyalty_level,
            MinOrder: this.props.loyaltyData.min_order_count,
            MaxOrder: this.props.loyaltyData.max_order_count,
            MinOrderVal: this.props.loyaltyData.min_order_value,
            MaxOrderVal: this.props.loyaltyData.max_order_value,
            earnCashbackType: this.props.loyaltyData.earn_cashback_type,
            earnCashbackValue: this.props.loyaltyData.earn_cashback_value,
            CashbackType: this.props.loyaltyData.cashback_type,
            CashbackVAlue: this.props.loyaltyData.cashback_value,
            CapedVal: this.props.loyaltyData.capped_value,
            expiry_date: this.props.loyaltyData.points_valid_till ? this.props.loyaltyData.points_valid_till : "",
            Discount: this.props.loyaltyData.discount_included_flag
        })
    }
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        // console.log(event.target.value)
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
          }
          if(event.target.value < 0) {
              toast.error("Negative values are not allowed")
          }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSaveBtnClick = (event: any) => {
        let { Discount, CapedVal,earnCashbackType,earnCashbackValue, CashbackVAlue, CashbackType, MaxOrderVal, MinOrderVal, MaxOrder, MinOrder, loyaltyName, LoyaltyLevel,expiry_date } = this.state;
        let { loyaltyTiers } = this.props;
        let id = this.props.match.params.id;
        let validateFlag = true;
        // addLoyalty Tier data below
        let obj:any = {
            loyalty_name: loyaltyName,
            loyalty_level: LoyaltyLevel,
            min_order_count: MinOrder,
            max_order_count: MaxOrder,
            min_order_value: MinOrderVal,
            max_order_value: MaxOrderVal,
            earn_cashback_type: earnCashbackType,
            earn_cashback_value: earnCashbackValue,
            cashback_type: CashbackType,
            cashback_value: CashbackVAlue,
            capped_value: CapedVal,
            points_valid_till: expiry_date,
            discount_included_flag: Discount
        }
        if(obj.min_order_count <= 0 || obj.max_order_count <= 0) {
            validateFlag = false;
            toast.error("Minimum and Maximum order count should be greater than zero")
        }
        else if(obj.max_order_value <= 0 || obj.min_order_value <= 0) {
            validateFlag = false;
            toast.error("Minimum and Maximum order value should be greater than zero")
        }
        else if(obj.earn_cashback_value <= 0 || obj.burn_cashback_value <= 0) {
            validateFlag = false;
            toast.error("Earn cashback and burn cashback should be greater than zero")
        }
        else if(obj.max_order_count < obj.min_order_count) {
            validateFlag = false;
            toast.error("Maximum order count should be greater than " + obj.min_order_count)
        }
        else if(obj.max_order_value <= obj.min_order_value) {
            validateFlag = false;
            toast.error("Maximum order value should be greater than " + obj.min_order_value)
        }
        else if(obj.earn_cashback_value > 100 && obj.earn_cashback_type == "percentage" || obj.cashback_value > 100 && obj.cashback_type == "percentage") {
            validateFlag = false;
            toast.error("Cashback Earn or Cashback Burn percentage should not be greater than 100")
        }
        else if(obj.capped_value <= 0 ) {
            validateFlag = false;
            toast.error("Capped value should be greater than zero")
        }
        else if(obj.points_valid_till <= 1 ) {
            validateFlag = false;
            toast.error("Expiry days should be greater than 1")
        }
        if(loyaltyTiers.length > 0 && validateFlag) {
            loyaltyTiers.map((tier: any) => {
                if(obj.loyalty_level == tier.loyalty_level) {
                    
                }
                else {
                    if (obj.min_order_count >= tier.min_order_count && obj.min_order_count <= tier.max_order_count || obj.max_order_count >= tier.min_order_count && obj.max_order_count <= tier.max_order_count) {
                        validateFlag = false;
                        toast.error("Minimum or maximum order count range is invalid")
                    }
                    if (obj.min_order_value >= tier.min_order_value && obj.min_order_value <= tier.max_order_value || obj.max_order_value >= tier.min_order_value && obj.max_order_value <= tier.max_order_value) {
                        validateFlag = false;
                        toast.error("Minimum or maximum order value range is invalid")
                    }
                }
            })
        }
        if(validateFlag) {
            this.props.editLoyaltyTier(id, obj)
        // addLoyalty Tier data above
        }
        
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        let { loyaltyData } = this.props;
        let { Discount, CapedVal,earnCashbackValue, CashbackVAlue, CashbackType, MaxOrderVal, MinOrderVal, MaxOrder, MinOrder, loyaltyName, LoyaltyLevel } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/add-combo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Loyalty Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/loyalty-tier" className="text-primary">Loyalty tier</Link></li>
                                <li className="breadcrumb-item active">Edit Loyalty</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">loyalty Name <span className="text-danger">*</span></label>
                                                            <input id="loyaltyName" onKeyDown={this.blockInvalidChar} value={loyaltyName} type="text" name="loyaltyName" required data-msg="Please enter loyalty Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Loyalty Level <span className="text-danger">*</span></label>
                                                            {/* <input id="LoyaltyLevel" type="text" name="LoyaltyLevel" defaultValue={LoyaltyLevel} className="input-material" onChange={this.handleInputChange} /> */}
                                                            <select id="MinOrder" name="LoyaltyLevel" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                <option value='First'  {...loyaltyData.loyalty_level === 'First' && { selected: true }}>First</option>
                                                                <option value='Second' {...loyaltyData.loyalty_level === 'Second' && { selected: true }}>Second</option>
                                                                <option value='Third' {...loyaltyData.loyalty_level === 'Third' && { selected: true }}>Third</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Min Order Count <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Minimum order count represents the minimum number of orders user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input id="MinOrder" onKeyDown={this.blockInvalidChar} value={MinOrder} type="number" name="MinOrder" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Max Order Count <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Maximum order count represents the maximum number of orders user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input id="MaxOrder" onKeyDown={this.blockInvalidChar} value={MaxOrder} type="number" name="MaxOrder" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Min Order Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Minimum order value represents the minimum orders value user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="MinOrderVal" onKeyDown={this.blockInvalidChar} type='number' value={MinOrderVal} className="input-material text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Max Order Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Maximum orders value represents the maximum orders value user holds to fullfil loyalty tiers requirement.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="MaxOrderVal" onKeyDown={this.blockInvalidChar} type='number' value={MaxOrderVal} className="input-material text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Earn Cashback Type <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Earn Cashback Type represents in which cashback type users earn points.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <select id="earnCashbackType" name="earnCashbackType" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu" >
                                                                <option value="value"  {...loyaltyData.earn_cashback_type === 'value' && { selected: true }}>value</option>
                                                                <option value="percentage"  {...loyaltyData.earn_cashback_type === 'percentage' && { selected: true }}>percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Earn Cashback Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Earn Cashback Value represents how much points user will be allocated on each order.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="earnCashbackValue" onKeyDown={this.blockInvalidChar} type='number' value={earnCashbackValue} className="input-material text-capitalize mt-2" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Burn Cashback Type <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Burn Cashback Type represent in which cashback type users radeem loyalty points.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <select id="CashbackType" name="CashbackType" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu" >
                                                                <option value="value"  {...loyaltyData.cashback_type === 'value' && { selected: true }}>value</option>
                                                                <option value="percentage"  {...loyaltyData.cashback_type === 'percentage' && { selected: true }}>percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Burn Cashback Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Burn Cashback Value represent how much cashback value users will radeem loyalty points on each order.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input name="CashbackVAlue" onKeyDown={this.blockInvalidChar} type='number' value={CashbackVAlue} className="input-material text-capitalize mt-2" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="row">
                                                <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Capped Value <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Capped Value is the Maximum limit of radeem points on a single order.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input
                                                                name="CapedVal"
                                                                value={CapedVal}
                                                                type="number"
                                                                onKeyDown={this.blockInvalidChar}
                                                                onChange={this.handleInputChange}
                                                                data-msg="Please enter group name"
                                                                className="input-material"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Discount Included <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Can user radeem points on discount promotion or discounted items?">
                                                            i
                                                            </span>
                                                            </label>
                                                            <select id="MinOrder" name="Discount" onChange={this.handleInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                <option value={1}  {...loyaltyData.discount_included_flag === 1 && { selected: true }}>Yes</option>
                                                                <option value={0} {...loyaltyData.discount_included_flag === 0 && { selected: true }}>No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label w-100">Loyalty Rewards Expiry After Days <span className="text-danger">*</span>
                                                            <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Number of days till how many days users points remain valid in this loyalty tier.">
                                                            i
                                                            </span>
                                                            </label>
                                                            <input
                                                                name="expiry_date"
                                                                value={this.state.expiry_date}
                                                                type="number"
                                                                data-msg="Please enter group name"
                                                                onChange={this.handleInputChange}
                                                                onKeyDown={this.blockInvalidChar}
                                                                className="input-material"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        loyaltyData: state.loyalty.loyaltyData,
        loyaltyTiers: state.loyalty.loyaltyTiers,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editLoyaltyTier: function (id: any, data: any) {
            dispatch(editLoyaltyTier(id, data));
        },
        getLoyaltyTier: function (id: number) {
            dispatch(getLoyaltyTier(id));
        },
        getLoyaltyTiers: function () {
            dispatch(getLoyaltyTiers());
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditLoyalty);