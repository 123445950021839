import React, { Component } from 'react';
import { AddCouponProps, AddCouponState } from '../../interfaces/coupon'
import { connect } from 'react-redux'
import { addCoupon, brandsList, getAllActiveGroups, logoutUser, itemsListOfActiveMenu, searchCustomerByPhone, getallActiveMenus } from '../../redux'
import { storesList } from '../../redux/actions/reportAction';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import Select from 'react-select';
import moment from 'moment';
var voucher_codes = require('voucher-code-generator');
class AddCoupon extends Component<AddCouponProps, AddCouponState> {
    constructor(props: any) {
        super(props);
        this.state = {
            couponname: "",
            coupondesc: "",
            couponvalue: "",
            couponcode: "",
            expiryDate: "",
            startDate: "",
            percent: "",
            mode: "all",
            channel: "all",
            type: "menu",
            discountType: "value",
            limit: "1",
            type_id: "",
            multiJson: [],
            multiMenuJson: [],
            multiGroupJson: [],
            multiStoreJson: [],
            freeDelivery: false,
            minamount: "",
            totalusagebycus: "",
            minorders: "",
            specificCustomer: false,
            phone: "",
            multiCustomerJson: []
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCouponType = this.handleCouponType.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | Coupons"
        this.props.storesList();
        this.props.itemsListOfActiveMenu();
        this.props.menusList();
        this.props.groupsList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleCouponType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }
    handleMenusInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiMenuJson: e });
        }
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiGroupJson: e });
        }
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiStoreJson: e });
        } else {
            this.setState({ multiStoreJson: [] });
        }
    }
    handleCustomersInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiCustomerJson: e });
        } else {
            this.setState({ multiCustomerJson: [] });
        }
    }
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            couponvalue: "",
            [event.target.name]: event.target.value,
        });
    }

    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    isCouponReady = () => {
        const { specificCustomer, multiCustomerJson, freeDelivery, couponname, couponvalue, couponcode, startDate, expiryDate, multiJson, multiStoreJson, multiGroupJson, multiMenuJson, mode, type, discountType, limit, type_id, percent } = this.state
        if (type == "menu") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiMenuJson && multiMenuJson.length > 0) && (percent !== "" || couponvalue !== "") && couponcode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "group") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiGroupJson && multiGroupJson.length > 0) && (percent !== "" || couponvalue !== "") && couponcode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "item") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && multiJson.length > 0 && (percent !== "" || couponvalue !== "") && couponcode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "store") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && multiStoreJson.length > 0 && (percent !== "" || couponvalue !== "") && couponcode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (freeDelivery) {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && discountType !== ""
                && limit !== "" && (percent !== "" || couponvalue !== "") && couponcode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        }
    }
    generateCouponCode = () => {
        let code: any[] = voucher_codes.generate({
            length: 8,
            count: 1,
            prefix: "promo-",
            postfix: `-${moment().format('YYYY')}`,
            charset: voucher_codes.charset("alphanumeric")
        });
        this.setState({ couponcode: code[0] });
    }
    handleFreeDelivery = (event: { target: { name: any; value: any; }; }) => {
        const { freeDelivery } = this.state;
        if (freeDelivery) {
            this.setState({ type: "menu", })
        } else {
            this.setState({ type: "" })
        }
        this.setState({ freeDelivery: !freeDelivery, type_id: "" })
    }
    handleForSpecificCus = (event: { target: { name: any; value: any; }; }) => {
        const { specificCustomer } = this.state;
        this.setState({ specificCustomer: !specificCustomer })
    }
    validatePhone(event: { target: { name: any; value: any; }; }) {
        if (event.target.value.length < 11) {
            this.setState({ phone: event.target.value })
        } else {
            if (event.target.value.length === 11) {
                this.setState({ phone: event.target.value });
            }
        }
        if (event.target.value.length >= 4) {
            this.props.searchCustomerByPhone(event.target.value)
        }
    };
    handleSaveBtnClick = (event: any) => {
        let { minorders, minamount, totalusagebycus, couponname, coupondesc, couponcode, couponvalue, startDate, channel, expiryDate, mode, type, limit, type_id, percent, multiJson, multiStoreJson,freeDelivery, specificCustomer, multiCustomerJson, multiMenuJson, multiGroupJson } = this.state;
        let data: any = {
            coupon_name: couponname,
            coupon_code: couponcode,
            coupon_description: coupondesc,
            coupon_value: couponvalue,
            start_date: moment(startDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
            expire_date: moment(expiryDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
            mode: mode,
            channel: channel,
            limit: limit,
            percent: percent,
            is_archive: 0,
            min_amount: minamount,
            min_orders: minorders,
            total_usage_customer: (totalusagebycus !== "" && totalusagebycus > 0) ? totalusagebycus : 0
        }
        if (type == "menu") {
            data.multiMenuJson = JSON.stringify(multiMenuJson);
        } else if (type == "group") {
            data.multiGroupJson = JSON.stringify(multiGroupJson);
        } else if (type == "item") {
            data.items_json = JSON.stringify(multiJson);
        } else if (type == "store") {
            data.stores_json = JSON.stringify(multiStoreJson);
        }
        if (specificCustomer) {
            data.customers_json = JSON.stringify(multiCustomerJson);
            data.for_customer = 1;
        }
        if (freeDelivery) {
            data.type = type;
            data.free_delivery = 1
        } else {
            data.free_delivery = 0;
            data.type = type;
        }
        this.props.addCoupon(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { menus, groups, items, stores, searchCustomers } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/0" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Coupon</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon name <span className="text-danger">*</span></label>
                                                            <input id="couponname" type="text" name="couponname" required data-msg="Please enter Coupon Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="coupondesc" type="text" name="coupondesc" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row my-3 d-flex justify-content-between align-items-center">
                                                    <div className="col-lg-7 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Code <span className="text-danger">*</span></label>
                                                            <input id="couponcode" type="text" name="couponcode" value={this.state.couponcode} required data-msg="Please enter Coupon Code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-6">
                                                        <button className="btn btn-sm btn-primary ml-2" title='Generate Coupon Code' onClick={() => this.generateCouponCode()}><i className="fa fa-refresh"></i></button>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-6 mt-3">
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom2" type="checkbox" name="freeDelivery" checked={this.state.freeDelivery} onChange={this.handleFreeDelivery} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Free Delivery</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.freeDelivery == false &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Type <span className="text-danger">*</span></label>
                                                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleCouponType}>
                                                                    <option value='menu'>Menu</option>
                                                                    <option value='group'>Group</option>
                                                                    <option value='item'>Item</option>
                                                                    <option value='store'>Store</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {this.state.type === "menu" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="menus"
                                                                        options={menus}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleMenusInputChange(e, i)}
                                                                    />
                                                                    {/* <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                        <option>Select Group</option>
                                                                        {groups &&
                                                                            groups.map((group: any, index: any) => (
                                                                                <option key={index} value={group.group_id}>{group.group_name} ({group.menu_name})</option>
                                                                            ))
                                                                        }
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.type === "group" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="groups"
                                                                        options={groups}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                    />
                                                                    {/* <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                        <option>Select Menu</option>
                                                                        {menus &&
                                                                            menus.map((menu: any, index: any) => (
                                                                                <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                            ))
                                                                        }
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "item" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="items"
                                                                        options={items}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "store" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Stores <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="stores"
                                                                        options={stores}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select channel" onChange={this.handleInputChange}>
                                                                <option value='all'>All</option>
                                                                <option value='delivery'>Delivery</option>
                                                                <option value='pickup'>Pick Up</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <select name="mode" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='all'>All</option>
                                                                <option value='online'>Online</option>
                                                                <option value='callcenter'>Call Center</option>
                                                                <option value='mobile'>Mobile</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Limit <span className="text-danger">*</span></label>
                                                            <input id="limit" type="number" name="limit" defaultValue='1' min="1" required data-msg="Please enter Limit" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Type <span className="text-danger">*</span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option value='value'>Value</option>
                                                                <option value='percentage'>Percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" type="number" name="percent" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Coupon Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon value <span className="text-danger">*</span></label>
                                                                <input id="couponvalue" type="number" name="couponvalue" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Coupon Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min total</label>
                                                            <input id="minamount" type="number" name="minamount" min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon usage/customer</label>
                                                            <input id="totalusagebycus" type="number" name="totalusagebycus" min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Orders</label>
                                                            <input id="minorders" type="number" name="minorders" min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-6 mt-3">
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom3" type="checkbox" name="specificCustomer" checked={this.state.specificCustomer} onChange={this.handleForSpecificCus} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">For Specific Customer</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.specificCustomer &&
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Phone</label>
                                                                <input id="phone" type="number" value={this.state.phone} onKeyDown={this.blockInvalidChar} name="phone" required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Customers <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="customers"
                                                                    options={searchCustomers}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleCustomersInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid from <span className="text-danger">*</span></label>
                                                            <input id="startDate" type="datetime-local" name="startDate" required data-msg="Please enter Start Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="datetime-local" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isCouponReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        menus: state.menu.allActiveMenus,
        groups: state.menu.allActiveGroups,
        items: state.menu.allItemsOfActiveMenu,
        message: state.coupon.message,
        isInserted: state.coupon.isInserted,
        stores: state.report.stores,
        searchCustomers: state.customer.searchCustomers
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addCoupon: function (data: any) {
            dispatch(addCoupon(data));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function () {
            dispatch(getallActiveMenus())
        },
        groupsList: function () {
            dispatch(getAllActiveGroups())
        },
        itemsListOfActiveMenu: function () {
            dispatch(itemsListOfActiveMenu())
        },
        storesList: () => {
            dispatch(storesList())
        },
        searchCustomerByPhone: (phone: any) => {
            dispatch(searchCustomerByPhone(phone))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);