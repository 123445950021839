import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, usersList, delUser,blockunblockuser } from '../../redux'
import { UserProps } from '../../interfaces/settings';

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.delUser(id,this.props.row.email_address)
    };
    handleBlockUnblock = (id: any, status: any) => {
        this.props.data.blockunblockuser(id, status,this.props.row.email_address);
    };
    render() { 
        const { row } = this.props
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        return (
            <div>
                {((row.role_id !== 7 && row.role_id !== 1) && roleId !== 7) &&
                    <>
                        <button title={row.status == 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.user_id}`} className={row.status == 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.status == 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                        <button title="Delete User" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelUser${row.user_id}`}><i className="fa fa-trash"></i></button>
                        <Link title="Edit User" className="btn btn-outline-primary ml-2" to={`/edit-user/${row.user_id}`}><i className="fa fa-edit"></i></Link>
                    </>
                }
                {roleId == 7 &&
                    <>
                        <button title={row.status == 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.user_id}`} className={row.status == 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.status == 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                        <button title="Delete User" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelUser${row.user_id}`}><i className="fa fa-trash"></i></button>
                        <Link title="Edit User" className="btn btn-outline-primary ml-2" to={`/edit-user/${row.user_id}`}><i className="fa fa-edit"></i></Link>
                    </>
                }
                {/* <!-- Modal--> */}
                <div id={`DelUser${row.user_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete User</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this user?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.user_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.user_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.status == 0 ? "UnBlock" : "Block"} User</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.status == 0 ? "UnBlock" : "Block"} this user?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.user_id, row.status)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
class Users extends Component<UserProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.usersList();
        document.title = "SimplexCMS | Users"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal:  () => { return <Redirect to="/add-user" /> },
            noDataText: 'Users Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Users Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.users} search={true} pagination={this.props.users.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='users.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='user_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='user_name' csvHeader='Name' width='120' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='120' columnTitle>Email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='city' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='country_name' csvHeader='Country' width='100' columnTitle>Country</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='address' csvHeader='Address' width='200' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_name' csvHeader='Group' width='150' columnTitle>Group</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='role_name' csvHeader='Role' width='100' columnTitle>Role</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='180' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>

                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        users: state.setting.users
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        usersList: function () {
            dispatch(usersList())
        },
        delUser: function (id: any,email_address:any) {
            dispatch(delUser(id,email_address))
        },
        blockunblockuser: function (id: any, status: any,email_address:any) {
            dispatch(blockunblockuser(id, status,email_address))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Users);