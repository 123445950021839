import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { logoutUser, getLoyalityGroups, updateLoyalityGroup } from '../../../redux';
import Toggle from 'react-toggle'
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"
import './style.css'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface LoyalityStates {
    [x: number]: any,
    data: any
}
interface LoyalityProps {
    history: any,
    groupsData: any,
    getLoyalityGroups: () => {}
}
class LoyalityGroups extends Component<LoyalityProps, LoyalityStates> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            data: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        this.props.getLoyalityGroups();
        document.title = "SimplexCMS | Menu"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {

        console.log("nextProps", nextProps.groupsData);
        this.setState({
            data: nextProps.groupsData
        })
    }
    
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        let { data } = this.state;

        if(!event.target.value || event.target.value > 0){
            if (data && data.length > 0) {
                var checkExist = data.find((x: any) => x.id == event.target.name);
                if (checkExist) {
                    checkExist.limit = event.target.value;
    
                    this.setState({
                        data: data
                    })
                }
            }
        }else{
            toast.error('Value Should be greater than 0 ', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }
        

    }
    
    handleSaveBtnClick = (event: any) => {
        let { data } = this.state;
        let checking:any[] = []
        data.forEach((element: any) => {
            if(element.is_active == 1){
                if(element.limit == '' || element.limit < 1){
                    checking.push(element);
                    toast.error(`${element.name} limit value is not correct`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                }
            }
        });
        if(checking.length == 0){
            this.props.updateLoyalityGroup(data, this.props.history);
        }
    }

    handleToggleChange(e: any, dataToggle: any) {
        let { data } = this.state;

        if (data && data.length > 0) {
            var checkExist = data.find((x: any) => x.id == dataToggle.id);
            checkExist.is_active = e.target.checked == false ? 0 : 1;

            this.setState({
                data: data
            })
        }
    }

    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { data } = this.state;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Loyalty Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables pt-4">
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col">
                                        <div className="card">

                                            <div className="card-body p-0 mb-5">
                                                <div className="container-fluid">

                                                    <div className="row">
                                                        <div className="col-lg-12 col-12">
                                                            <div className="card-header">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <strong>Enable Loyalty Cards</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {data.length > 0 ? data.map((element: any, index: any) => {
                                                                            return (
                                                                                <div className="col-12 my-2">
                                                                                    {/* <div className="d-flex justify-content-between"> */}
                                                                                    <div className="d-flex">
                                                                                        <div className="col-7">
                                                                                            {element.name}
                                                                                        </div>

                                                                                        <div className="col-2">
                                                                                            <Toggle
                                                                                                className='custom-classname'
                                                                                                checked={element.is_active == 0 ? false : true}
                                                                                                icons={false}
                                                                                                onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                        </div>
                                                                                        <div className="col-3">
                                                                                            {element.is_active == 1 && <input placeholder="Limit" type="number" name={element.id} min="1" value={element.limit} required data-msg="Please enter Value" className="input-material" onChange={this.handleInputChange} />}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) :
                                                                            <div className="col-12 text-center">
                                                                                <h5>Loyalty Card Groups not found</h5>
                                                                            </div>}

                                                                    </div>
                                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                                        <button className='btn btn-primary' onClick={this.handleSaveBtnClick}>Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        groupsData: state.loyalty.groupsData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getLoyalityGroups: () => {
            dispatch(getLoyalityGroups());
        },
        updateLoyalityGroup: (data: any, history: any) => {
            dispatch(updateLoyalityGroup(data, history))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoyalityGroups);