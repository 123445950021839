import React, { Component } from 'react';
import { AddModProps, AddModState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { menusList, groupsListByMenuId, modGroupsList, addModifier, logoutUser, storesListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import Footer from '../../../components/footer/main';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
class AddMod extends Component<AddModProps, AddModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modGroupJson: [],
            modifiername: "",
            erpid: null,
            poscode: null,
            priority: "",
            modifiertype: "",
            modifiercost: "",
            modifiersale: "",
            images: null,
            is_lsm: '0',
            storesJson: [],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModGroupsInputChange = this.handleModGroupsInputChange.bind(this);
    }
    componentDidMount() {
        this.props.modGroupsList();
        this.props.storesList();
        document.title = "SimplexCMS | Modifiers"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    fileSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ images: e.target.files })
        }
    }
    isModifierReady = () => {
        const { modifiername, modGroupJson, modifiercost, modifiersale, images, is_lsm, storesJson } = this.state
        if (is_lsm == "0") {
            return (modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && (images && images.length > 0));
        } else if (is_lsm == "1") {
            return (modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && (images && images.length > 0) && storesJson.length > 0);
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleSaveBtnClick = (event: any) => {
        let { modGroupJson, erpid, poscode, priority, modifiername, modifiertype, modifiercost, modifiersale, is_lsm, storesJson } = this.state;
        const data: any = new FormData()
        if (this.state.images) {
            for (var x = 0; x < this.state.images.length; x++) {
                data.append('files', this.state.images[x])
            }
        }
        data.append('modifier_name', modifiername)
        if (erpid !== null) {
            data.append('erp_id', erpid)
        }
        if (poscode !== null) {
            data.append('pos_code', poscode)
        }
        data.append('modifier_type', modifiertype)
        data.append('modifier_cost_price', modifiercost)
        data.append('modifier_sale_price', modifiersale)
        data.append('priority', priority)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        this.props.addModifier(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { modGroupJson, is_lsm, storesJson } = this.state;
        const { isInserted, message, modgroups } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-modifier" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/modifiers" className="text-primary">Modifiers</Link></li>
                                <li className="breadcrumb-item active">Add Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier name <span className="text-danger">*</span></label>
                                                            <input id="modifiername" type="text" name="modifiername" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8 col-sm-8 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                value={modGroupJson}
                                                                options={modgroups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Type</label>
                                                            <input id="modifiertype" type="text" name="modifiertype" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-7">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Modifier Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="modifiercost" type="number" name="modifiercost" required data-msg="Please enter Cost Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                            <input id="modifiersale" type="number" name="modifiersale" required data-msg="Please enter Sale Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModifierReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groups: state.menu.groups,
        modgroups: state.menu.modgroups,
        stores: state.menu.storesoptions,
        message: state.menu.message,
        isInserted: state.menu.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModifier: function (data: any) {
            dispatch(addModifier(data));
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsListByMenuId: function (id: any) {
            dispatch(groupsListByMenuId(id))
        },
        modGroupsList: function () {
            dispatch(modGroupsList())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMod);