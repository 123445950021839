import { BIRTHDAY_OFFERS, FETCH_LAST_LOYALTY_CODE, GET_BIRTHDAY_OFFER, GET_COFFEE_CARD_GROUP, GET_LOYALITY_GROUPS, GET_LOYALITY_ITEMS, GET_LOYALTY_COUNT, GET_LOYALTY_TIER, GET_SMOOTHIE_CARD_GROUP, LOYALTY_TIERS, SEARCH_MENU_ITEM } from '../actions/loyaltyType';
import { SEARCH_GROUPS } from '../actions/menuType';
import { PAYMENTS_LIST } from '../actions/paymentType'
const initialState: any = {
    loyaltyTiers: [] || "",
    birthdayOffers: [] || "",
    groupsData: [] || "",
    loyaltyData: {},
    birthdayOfferData: {},
    loyaltyCountData: {},
    lastLoyaltyCode: [] || "",
    coffeeCardGroupData: [] || "",
    smoothieCardGroup: [] || "",
}

const LoyaltyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOYALTY_TIERS: return {
            ...state,
            loyaltyTiers: action.payload
        }
        case GET_LOYALTY_TIER: return {
            ...state,
            loyaltyData: action.payload
        }
        case BIRTHDAY_OFFERS: return {
            ...state,
            birthdayOffers: action.payload
        }
        case GET_BIRTHDAY_OFFER: return {
            ...state,
            birthdayOfferData: action.payload
        }
        case GET_LOYALTY_COUNT: return {
            ...state,
            loyaltyCountData: action.payload
        }
        case GET_LOYALITY_ITEMS: return {
            ...state,
            itemsData: action.itemsData
        }
        case SEARCH_MENU_ITEM: return {
            ...state,
            searchItemData: action.searchItemData
        }
        case SEARCH_GROUPS: return {
            ...state,
            searchGroupData: action.searchGroupData
        }
        case GET_LOYALITY_GROUPS: return {
            ...state,
            groupsData: action.payload
        }
        case FETCH_LAST_LOYALTY_CODE: return {
            ...state,
            lastLoyaltyCode: action.payload
        }
        case GET_COFFEE_CARD_GROUP:
        return {
            ...state,
            coffeeCardGroupData: action.payload
        }
        case GET_SMOOTHIE_CARD_GROUP:
        return {
            ...state,
            smoothieCardGroup: action.payload
        }
        default: return state;
    }
}
export default LoyaltyReducer;