import React, { Component } from 'react';
import { AddMenuItemProps, AddMenuItemState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addItems, checkDuplicateErpId, checkDuplicatePosCode, groupsList, logoutUser, menusList, statesList, storesListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class AddMenuItem extends Component<AddMenuItemProps, AddMenuItemState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            itemname: "",
            group: "",
            type: "",
            priority: "",
            images: null,
            hero_image: null,
            hero_mobile_image: null,
            hero_item: false,
            settime: 'alltime',
            tax_percent: '',
            itemstart: "",
            itemclose: "",
            state: "",
            mode: [],
            is_lsm: '0',
            storesJson: [],
            metaTitle: "",
            metaDesc: "",
            sizejson: [
                {
                    pos_code: "",
                    erp_id: "",
                    size: "",
                    cost: "",
                    mrp: "",
                    extra_price: "",
                    description: "",
                    image_url: null,
                    alt_text: ""
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleItemsByGroupId = this.handleItemsByGroupId.bind(this);
    }
    componentDidMount() {
        this.props.menusList();
        this.props.groupsList();
        this.props.storesList();
        document.title = "SimplexCMS | Menu Items"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleItemsByGroupId(event: { target: { name: any; value: any; }; }) {
        this.setState({
            group: event.target.value,
            sizejson: [{
                pos_code: "",
                erp_id: "",
                size: "",
                cost: "",
                mrp: "",
                extra_price: "",
                description: "",
                image_url: null,
                alt_text: ""
            }]
        });
    }
    // handle click event of the Time Radio buttons
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleVariantInputChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const list: any = this.state.sizejson;
        list[index][name] = value;
        this.setState({ sizejson: list });
        if (name == "pos_code") {
            this.props.checkDuplicatePosCode(value)
        }
        if (name == "erp_id") {
            this.props.checkDuplicateErpId(value)
        }
    };
    // handle click event of the Remove button
    handleRemoveClick = (index: any) => {
        const list = this.state.sizejson;
        list.splice(index, 1);
        this.setState({ sizejson: list });
    };
    // handle click event of the Add button
    handleAddClick = (i: any) => {
        const list: any = this.state.sizejson;
        let { duplicateItemErp, duplicateItemPos } = this.props;
        if (list[i]["size"] == "" || list[i]["cost"] == "" || list[i]["mrp"] == "" || (list[i]["pos_code"] !== "" && duplicateItemPos == true) || (list[i]["erp_id"] !== "" && duplicateItemErp == true) || list[i]["image_url"] == null) {
            alert("Please fill all mandatory variant fields")
        } else if (i == '3') {
            alert("Sorry you can select only 4 variants")
        } else {
            const newlist: any = { pos_code: "", erp_id: "", size: "", cost: "", mrp: "", extra_price: "", description: "", image_url: null }
            const menu_items: any = this.state.sizejson.concat(newlist)
            this.setState({ sizejson: menu_items });
        }
    };
    fileSelectedHandler = (e: any, index: any) => {
        const list: any = this.state.sizejson;
        if (e.target.files.length > 0) {
            list[index]["image_url"] = e.target.files;
            this.setState({ sizejson: list });
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleHeroItem = (event: { target: { name: any; value: any; }; }) => {
        const { hero_item } = this.state;
        this.setState({ hero_item: !hero_item })
    }
    heroItemImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_image: e.target.files })
        }
    }
    heroItemMobileImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_mobile_image: e.target.files })
        }
    }
    isMenuItemReady = () => {
        const { itemname, group, settime, itemstart, itemclose, hero_item, mode, hero_image, is_lsm, storesJson } = this.state;
        let { duplicateItemPos, duplicateItemErp } = this.props;
        if (settime == 'customtime') {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && itemstart !== "" && itemclose !== "" && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && itemstart !== "" && itemclose !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                }
            } else {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && hero_image !== null && itemstart !== "" && itemclose !== "" && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && hero_image !== null && itemstart !== "" && itemclose !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                }
            }
        } else {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                }
            } else {
                if (is_lsm == "0") {
                    return (itemname !== "" && group !== "" && hero_image !== null && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                } else if (is_lsm == "1") {
                    return (itemname !== "" && group !== "" && hero_image !== null && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true);
                }
            }
        }
    }
    isUnique = (arr: any) => {
        const len = arr.length;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                // if the elements match, this wouldn't be a unique array
                if ((arr[i].pos_code !== "" && arr[j].pos_code !== "") || (arr[i].erp_id !== "" && arr[j].erp_id !== "")) {
                    if (i !== j && (arr[i].pos_code === arr[j].pos_code || arr[i].erp_id === arr[j].erp_id)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    handleSaveBtnClick = (event: any) => {
        let { itemname, group, type, priority, mode, hero_item, hero_image,hero_mobile_image, sizejson, settime, itemstart, itemclose, is_lsm, storesJson, metaTitle, metaDesc } = this.state;
        const data: any = new FormData();
        if (this.state.sizejson) {
            for (var x = 0; x < this.state.sizejson.length; x++) {
                if (this.state.sizejson[x].image_url !== null) {
                    var file = this.state.sizejson[x].image_url[0];
                    var newFileName = file.name.split(".")[0] + `_variant_${x}.` + file.name.split(".")[1];
                    data.append('files', file, newFileName)
                }
            }
        }
        const SizeJsonString = JSON.stringify(sizejson)
        if (hero_image) {
            var file = hero_image[0];
            var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if(hero_mobile_image){
            var file = hero_mobile_image[0];
            var newFileName = file.name.split(".")[0] + "_hero_mobile_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        data.append('item_name', itemname)
        // if (erpid !== null) {
        //     data.append('erp_id', erpid)
        // }
        // if (poscode !== null) {
        //     data.append('pos_code', poscode)
        // }
        if (hero_item === true) {
            data.append('hero_item', 1)
        }
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        data.append('item_group_id', group)
        data.append('item_mode', JSON.stringify(mode))
        // data.append('item_type', type)
        data.append('priority', priority)
        data.append('meta_title', metaTitle)
        data.append('meta_description', metaDesc)
        if (settime === 'customtime') {
            data.append('item_start_time', moment(itemstart).utc(false))
            data.append('item_close_time', moment(itemclose).utc(false))
        }
        if (sizejson[sizejson.length - 1]["size"] !== "" && sizejson[sizejson.length - 1]["cost"] !== "" && sizejson[sizejson.length - 1]["mrp"] !== "" && sizejson[sizejson.length - 1]["image_url"] !== null) {
            if (this.isUnique(sizejson)) {
                data.append('itemSizes', SizeJsonString)
                this.props.addItems(data);
            } else {
                toast.error("Please fill unique POS & ERP codes", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            }
        } else {
            alert("Please fill all mandatory variant fields")
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, groups } = this.props;
        const { sizejson, settime, hero_item, is_lsm } = this.state;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-item" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menu Item Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/menu-items" className="text-primary">Menu Items</Link></li>
                                <li className="breadcrumb-item active">Add Menu Item</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item name <span className="text-danger">*</span></label>
                                                            <input id="itemname" type="text" name="itemname" required data-msg="Please enter Item Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="itemdesc" type="text" name="itemdesc" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group name <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleItemsByGroupId}>
                                                                <option>Select Group</option>
                                                                {groups &&
                                                                    groups.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.group_id}>{group.group_name + "-" + group.menu_name + "-" + group.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Type</label>
                                                            <input id="type" type="text" name="type" required data-msg="Please enter Item Type" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta title </label>
                                                            <input id="metaTitle" type="text" name="metaTitle" required className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta description</label>
                                                            <input id="metaDesc" type="text" name="metaDesc" required className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Item Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row">
                                                    <div className="col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Variants</label>
                                                            {sizejson.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">POS Code</label>
                                                                                    <input type="number" name="pos_code" value={x.pos_code} className="input-material" onChange={e => this.handleVariantInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">ERP Id</label>
                                                                                    <input type="text" name="erp_id" value={x.erp_id} className="input-material" onChange={e => this.handleVariantInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Size <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        name="size"
                                                                                        type="text"
                                                                                        value={x.size}
                                                                                        data-msg="Please enter size"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Cost <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        name="cost"
                                                                                        type="number"
                                                                                        value={x.cost}
                                                                                        data-msg="Please enter cost"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Max Retail Price <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        name="mrp"
                                                                                        type="number"
                                                                                        value={x.mrp}
                                                                                        data-msg="Please enter mrp"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Extra Price</label>
                                                                                    <input
                                                                                        name="extra_price"
                                                                                        type="number"
                                                                                        value={x.extra_price}
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleVariantInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Description</label>
                                                                                    <input id="description" type="text" name="description" value={x.description} className="input-material" onChange={(e) => this.handleVariantInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Item Image <span className="text-danger">*</span></label>
                                                                                    <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={(e) => this.fileSelectedHandler(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Alt Tag</label>
                                                                                    <input id="alt_text" type="text" name="alt_text" className="input-material" onChange={(e) => this.handleVariantInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-2 col-6 py-2 px-2">
                                                                                {sizejson.length !== 1 &&
                                                                                    <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                {sizejson.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddClick(i)}><i className="fa fa-plus"></i></button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {hero_item &&
                                                    <div className='row'>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Desktop)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Mobile)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={hero_item} onChange={this.handleHeroItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Hero Item</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    settime === 'customtime' &&
                                                    <div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Menu Item Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemstart" type="datetime-local" name="itemstart" required data-msg="Please enter starting time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemclose" type="datetime-local" name="itemclose" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isMenuItemReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groups: state.menu.groups,
        message: state.menu.message,
        states: state.setting.states,
        menus: state.menu.menus,
        stores: state.menu.storesoptions,
        isInserted: state.menu.isInserted,
        duplicateItemPos: state.menu.duplicateItemPos,
        duplicateItemErp: state.menu.duplicateItemErp
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addItems: function (formData: any) {
            dispatch(addItems(formData));
        },
        checkDuplicatePosCode: (pos_code: any) => {
            dispatch(checkDuplicatePosCode(pos_code))
        },
        checkDuplicateErpId: (erp_id: any) => {
            dispatch(checkDuplicateErpId(erp_id))
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        menusList: function () {
            dispatch(menusList())
        },
        statesList: function () {
            dispatch(statesList())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMenuItem);